import CategoryDrawer from "./CVPage/CategoryDrawer"
import { Box, Grid2, Paper } from "@mui/material"
import CVHeader from "./CVPage/CVHeader"
import CVFooter from "./CVPage/CVFooter"
import CVContent from "./CVPage/CVContent"

/*
 * A more interactive CV page to replace my LinkedIn at some point (I am OUT on LinkedIn)
 * What is special: 
 *  - Uses CSS effects for more interactability
 *  - Explores the boundaries of new Grid2 from MUI
 *  - Tries to look nice with fonts and other things
 *  - Created from Figma scribbling (waht, acthually planned content?!) (in docs)
 */ 

const CVPage = () => {

    return (<div>
        <Box sx={{position:"relative", alignItems: "center", justifyContent: "center", display:"flex", marginTop: 4}}>
          <Box sx={{width: {xs: "95vw",sm: "80vw", md: "60vw"}, height: "130vh", backgroundColor: "#e07272"}}>
            <Grid2 container direction="column" sx={{overflow: "hidden"}}>
                <CVHeader/>

                {/*Contains the Content of the CV in a Grid*/}
                <CVContent/>
                <CVFooter/>
            </Grid2>
            <Box sx={{marginBottom: 4}}/>
            </Box>
        </Box>
    </div>
    )  
}

export default CVPage