import { Grid2,Paper,Typography,Box,  } from "@mui/material"
import me from "../../../../../assets/me2mirror.png"
import me2 from "../../../../../assets/meYes3.png"
const Column2 = ({boxStates, flip, handleFlip}) => {
    return(<Grid2 size={boxStates[0] === boxStates[1] === boxStates[2] === boxStates[3] ? 
        (2) : (1)} 
        sx={{transition: "width 0.75s ease-in-out"}}>
        <Grid2 container direction="column" >
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: (boxStates[2] || boxStates[3]) ? ("50vh") : ("10vh"), transition: "height 0.75s ease-in-out", backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: "5vh" , backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                    <Box onClick={handleFlip} sx={{ perspective: '1000px', width: "100%", height: "100%", transition: "height 0.75s ease-in-out", height: boxStates[0] === boxStates[1] === boxStates[2] === boxStates[3] ? ("45vh") : ("20vh") }}>
                        <Box
                            sx={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            transformStyle: 'preserve-3d',
                            transition: 'transform 0.6s',
                            transform: flip>2 ? 'rotateY(180deg)' : 'rotateY(0deg)',
                            }}
                        >
                            <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backfaceVisibility: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                            <img
                                src={me}
                                width="100%"
                                height="100%"
                                style={{position: "relative", transition: "border-radius 0.75s ease-in-out", borderRadius: boxStates[0] === boxStates[1] === boxStates[2] === boxStates[3] ? ("75px") : ("50px"), opacity: 0.75}}
                            />
                            </Box>
                            <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backfaceVisibility: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                transform: 'rotateY(180deg)',
                            }}
                            >
                            <img
                                src={me2}
                                width="100%"
                                height="100%"
                                style={{ transition: "border-radius 0.75s ease-in-out", borderRadius: boxStates[0] === boxStates[1] === boxStates[2] === boxStates[3] ? ("75px") : ("50px")}}
                            />
                            </Box>
                        </Box>
                        </Box>
                    
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: (boxStates[0] || boxStates[1]) ? ("50vh") : ("10vh"),transition: "height 0.75s ease-in-out",backgroundColor:"transparent"}}></Paper>

            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: "5vh", backgroundColor:"transparent"}}></Paper>
            </Grid2>
        </Grid2> 
    </Grid2>)
}

export default Column2