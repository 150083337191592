export const contentRussian = [
    { phrase: "Доброе утро!", trans: "Good morning!" },
    { phrase: "Добрый день!", trans: "Good afternoon!" },
    { phrase: "Добрый вечер!", trans: "Good evening!" },
    { phrase: "Здравствуйте!", trans: "Hello!" },
    { phrase: "Привет!", trans: "Hi!" },
    { phrase: "Как дела?", trans: "How are you?" },
    { phrase: "Хорошо! Спасибо!", trans: "I’m fine! Thank you!" },
    { phrase: "Пожалуйста!", trans: "Please / You are welcome!" },
    { phrase: "Пожалуйста!", trans: "You are welcome!" },
    { phrase: "Не за что!", trans: "My pleasure!" },
    { phrase: "До свидания!", trans: "Goodbye!" },
    { phrase: "Пока!", trans: "Bye!" },
    { phrase: "Увидимся!", trans: "See you later!" },
    { phrase: "Хорошего дня!", trans: "Have a good day!" },
    { phrase: "Простите / извините!", trans: "Sorry!" },
    { phrase: "Ничего страшного!", trans: "It’s OK!" },
    { phrase: "Как вас зовут?", trans: "What is your name?" },
    { phrase: "Приятно познакомиться.", trans: "Nice to meet you." },
    { phrase: "Меня зовут", trans: "My name is" },
    { phrase: "Откуда вы?", trans: "Where are you from?" },
    { phrase: "Вы говорите по?", trans: "Do you speak?" },
    { phrase: "Говорите медленнее, пожалуйста!", trans: "Speak slower please!" },
    { phrase: "Не понимаю. Повторите, пожалуйста!", trans: "I do not understand. Please repeat!" },
    { phrase: "Пожалуйста, напишите это!", trans: "Please write this!" },
    { phrase: "Помогите, пожалуйста!", trans: "Help me, please!" },
    { phrase: "Чувак/Чувиха", trans: "Dude/Dudette" },
    { phrase: "Бомба", trans: "Bomb/Amazing" },
    { phrase: "Всё ништяк", trans: "It’s all good" },
    { phrase: "Как сам?", trans: "What’s up?" },
    { phrase: "Офигеть", trans: "No way/Wow" },
    { phrase: "Круто", trans: "Cool" },
    { phrase: "Да не гони!", trans: "Seriously?" },
    { phrase: "Хавать", trans: "To eat" },
    { phrase: "Бухать", trans: "To drink" },
    { phrase: "Бухой/Бухая", trans: "Drunk" },
    { phrase: "Тусить", trans: "To hang out" },
    { phrase: "Хрен знает", trans: "Who knows?" },
    { phrase: "Мне до лампочки", trans: "I don’t care" },
    { phrase: "Зачет", trans: "Noted" },
    { phrase: "Бабки", trans: "Money" },
    { phrase: "Чёрт", trans: "Shoot!" },
    { phrase: "Блин", trans: "Damn it/Shit" },
    { phrase: "Сука", trans: "Bitch" },
    { phrase: "Грузить", trans: "To bore with too much talking" },
    { phrase: "Лох", trans: "Gullible/Idiot" },
    { phrase: "Достал/Досталa", trans: "To be annoyed" },
    { phrase: "Бычить", trans: "To be angry" },
    { phrase: "Кайфонуть", trans: "To have a pleasant pastime" },
    { phrase: "Америкос", trans: "American" }
];