export const rules = [

    {
        url: "https://gmtwebsiteassets.s3.us-west-2.amazonaws.com/triumphtragedy/TnT3rdEdRulesFinal.pdf",
        text: "Triumph rules",
    },
    {
        url:"https://gmtwebsiteassets.s3.us-west-2.amazonaws.com/ConquestAndConsequence/Conquest%26Consequence_Rulebook_v1.1e.pdf",
        text: "Conquest rules"
    },
    {
        url:"https://boardgamegeek.com/file/download_redirect/1e5a2bea0b486160b176f1c9fc4878a91241962c88a8c774/C%2BT+-+Rulebook+v01t.pdf",
        text: "Conquest & Tragedy rules"
    },
    {
        url:"https://boardgamegeek.com/file/download_redirect/e767d050e7424d9a30392245f13a64a69a86c26812f0e252/Conquest+%26+Consequence+-+Player+Aids+v1.1e.pdf",
        text:"Conquest aids"
    }
]