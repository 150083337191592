import { SHOW_IMAGES, LOAD_IMAGES, SHUFFLE_IMAGES, HIDE_IMAGES } from "../constants"

import {v4 as uuidv4} from "uuid"
const initialState = []

const imageReducer = (state = initialState, action) => {
    switch(action.type) {
        // Load images and fill in game,related information
        case LOAD_IMAGES:
            const imageArray = action.payload.map(item => ({
                info: item,
                hidden: true,
                id: uuidv4(),
                found: false
            }))
            return imageArray

        case SHOW_IMAGES:
            
            return action.payload

            
        case HIDE_IMAGES: 
            // Hide non-found images
            const found = state.filter(image => image.found === true)
            const hidden = state.filter(image => image.hidden === false)


            if(found.length+2 <= hidden.length) {
                return state.map(item =>
                    !item.hidden && !item.found
                    ? { 
                    ...item,
                    hidden: true,
                    }
                    :item)
            }
            return state
        case SHUFFLE_IMAGES:
            //  Shuffle image array
            const array = state
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }

            return array

        default:
            return state
    }
}

export default imageReducer