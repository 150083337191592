import { rules } from "./ruleAssets"
import PaperBack from "../../components/PaperBack"
import PaperContent from "../../components/PaperContent"
import { Grid,Typography,Link } from "@mui/material"

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"

const OnlyRules = ({isSliding}) => {
    
    return(<div>

        <PaperBack isSliding={isSliding} order={-1}>
            <Grid item xs={6} md={6}>
                <Typography variant="h3" color="primary" margin={5}>All rules</Typography>
            </Grid>
            <PaperContent width={"100%"} height={"100%"}>
                <Grid container direction="column" sx={{ display: 'flex', alignItems: 'center' }}>
                    {rules.map((item) => (
                        <Grid item xs={6} md={6} className="fade-in" key={item.text}>
                            <Typography variant="h4" margin={4}>
                        <Link href={item.url}
                            underline="hover"
                            target="_blank" 
                            rel="noopener noreferrer">
                            {item.text}
                            <ArrowForwardRoundedIcon/>
                        </Link>
                        </Typography>
                    </Grid>
                    ))}
                </Grid>
            </PaperContent>
        </PaperBack>  
    </div>)
}

export default OnlyRules