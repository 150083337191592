import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeNotification, setNotification } from "../../Model/redux/actionCreators/notificationActions";

const Notification = ({notification}) => {

    const dispatch = useDispatch()
    const notifications = useSelector(state => state.notification)

    // Handles notification cycling
    useEffect(() => {
        const existingNotification = notifications.find(notif => notif.category === notification.category);
        
        if (existingNotification) {
            dispatch(removeNotification(notification));
        }

        dispatch(setNotification(notification))
        setTimeout(() => {
            dispatch(dispatch(removeNotification(notification)));
        }, 1500);
    },[]);

    return(
        <div>
            <div>
                <Alert className="alert" severity={notification.severity}>
                    {notification.status}
                </Alert>
            </div>
        </div>
    )
}


export default Notification