import {useSelector} from "react-redux"
import Notification from "./Notification.jsx";

const Notifications = () => {

    const notifications = useSelector(state => state.notification)
    return (
            <div> 
                <ul>
                {notifications.map((notification) => (
                    <div key={notification.type}>
                        <Notification notification={notification} />
                    </div>
                ))}
                </ul>
            </div>  
    )
}

export default Notifications