export const SEVERITY = {
    success: "success",
    loading: "info",
    error: "error",
}
export const REQUEST_TYPE = {
    auth: {
        successLogin: "Authorization successful!",
        successLogout: "Logout successful!",
        loading: "Logging in...",
        errorAuth: "Authorization failed!",
        errorLogout: "Logout failed!",
        errorAuthCreds: "Insufficient credentials!"
    },
    cart: {
        success: "Added to cart!",
        loading: "Adding to cart...",
        error: "Adding to cart failed!"
    },
    product: {
        successRemove: "Product removed!",
        successChange: "Product changed!",
        successLoading: "Products loaded!",
        loading: "Altering product list...",
        errorRemove: "Product remove failed!",
        errorChange: "Product change failed!",
        errorLoading: "Product loading failed!"
    },
    user: {
        successUpdate: "User updated!",
        successLoading: "Users loaded!",
        loading: "Updating user...",
        errorLoading: "User loading failed!",
        errorUpdate: "Updating user failed!"
    },
    order: {
        success: "Orders received!",
        loading: "Getting orders...",
        error: "Getting orders failed!"
    },
    memgame: {
        pairFound: "Found a pair!",
        completed: "Puzzle solved, congrats!",
        error: "Wrong pair!"
    },
    success: "success",
    loading: "loading",
    error: "error",
}

export const stateTypes = {
    product: "product", // For the product state
    user: "user", // For the user state
    order: "order", // For the order state
    cart: "cart", // For the cart state
    auth: "auth", // For the auth state
    memgame: "memgame",
    winGame: "winGame"
  };