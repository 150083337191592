import { useState } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import { Box,Drawer,List,ListItem,IconButton, Typography, SwipeableDrawer  } from "@mui/material";

export default function CategoryDrawer() {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    const ItemDrawer = (

        <Box sx={{ width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }} role="presentation" onClick={toggleDrawer(false)}>
            <List sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                <ListItem>
                    <Typography>HELLO</Typography>
                </ListItem>
        
            </List>
        </Box>
    )

    return (
    <div>
        <IconButton
       size="large"
       edge="start"
       color="inherit"
       aria-label="menu"
       sx={{ mr: 2 }}
       onClick={toggleDrawer(true)}>
       <AppsIcon/>
      </IconButton>
      <SwipeableDrawer open={open} onClose={toggleDrawer(false)}  onOpen={toggleDrawer(true)} anchor={"top"} ModalProps={{
          keepMounted: true,
        }}>
        {ItemDrawer}
      </SwipeableDrawer>
    </div>

       
    )
}