import { Button, Divider } from "@mui/material"
import { useNavigate } from "react-router-dom"

/*
* Redirects to a page using a button
*/ 

const Navigate = ({url, title, children}) => {
    const nav = useNavigate()
    const handleRedirect = () => {
        nav(url)  
    }

    return(
        <div>
            <Button onClick={handleRedirect} variant="contained" color="primary" sx={{width: 150, fontSize: "12px",margin: 0.3,borderWidth: '2px', borderColor: 'secondary.main'}} >
                {children}&nbsp;{title}
            </Button>
        </div>
    )
}

export default Navigate