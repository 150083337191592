import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './View/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@mui/material/styles"
import store from "./Model/redux/store"
import theme from "./styles/theme"
import { Provider } from "react-redux"
import { BrowserRouter, HashRouter } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
