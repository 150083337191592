import { loadImages, showImages, shuffleImages, matchImages, hideImages } from "../Model/redux/actionCreators/imageActions"
import { REQUEST_TYPE, stateTypes, SEVERITY } from "../noticonstants"
import { setNotification } from "../Model/redux/actionCreators/notificationActions"

export const handleLoad = (dispatch, images) => {
    const li = dispatch(loadImages(images))
    dispatch(shuffleImages(li.payload))
}

export const handleMemgameLogic = (dispatch,im,all) => {
    const pair = all.find(image => image.info.type === im.info.type && image.id !== im.id)

    if(pair.hidden && im.hidden) {
        const operated = all.map(item =>
            item.id === im.id
            ? { 
            ...item,
            hidden: false,
            }
            :item)
            console.log(operated)
        dispatch(showImages(operated))

        const shown = operated.filter(item => item.hidden === false)
        if(shown.length % 2 === 0) {
            dispatch(setNotification({
                type: REQUEST_TYPE.error,
                severity: SEVERITY.error,
                status: REQUEST_TYPE.memgame.error,
                category: stateTypes.memgame
                }));
            setTimeout(() => {
        
            dispatch(hideImages(im))
        
        }, 1500)
        }
           
    }
    else if(!pair.hidden && im.hidden) {
        const hidden = all.map(item =>
            item.id === im.id
            ? { 
            ...item,
            hidden: false,
            }
            :item)
        console.log(hidden)
        const matched = hidden.map(item =>
            item.info.type === im.info.type
            ? { 
            ...item,
            found: true,
            }
            :item)
        dispatch(showImages(matched))
        const win = matched.filter(item => item.found === true)
        console.log(win)
        if(win.length === 16) {
            dispatch(setNotification({
                type: REQUEST_TYPE.memgame,
                severity: SEVERITY.success,
                status: REQUEST_TYPE.memgame.completed,
                category: stateTypes.winGame
            }));
        }
        else {
            dispatch(setNotification({
                type: REQUEST_TYPE.success,
                severity: SEVERITY.success,
                status: REQUEST_TYPE.memgame.pairFound,
                category: stateTypes.memgame
            }));
        }
        
        
        
    }
}