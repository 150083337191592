import { Grid2,Paper,Typography,Box, Divider,Chip  } from "@mui/material"

const Column3 = ({boxStates, handleIhave, handleIwant}) => {
    return(<Grid2 size={(boxStates[0] || boxStates[2]) ? 
        (1) : ((boxStates[1] || boxStates[3])  ?
         (4) : (2))} 
         sx={{transition: "width 0.75s ease-in-out", transformOrigin: "left"}} >
        <Grid2 container direction="column">
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: "5vh", backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper onClick={handleIhave} elevation={10} sx={{fontFamily: "Playpen Sans, cursive",overflow: "auto",transition: "height 0.75s ease-in-out",height: boxStates[3] ? ("10vh") : (boxStates[1] ? ("70vh") : ("35vh")), backgroundColor: "#d9d9d9"}}>
                    <Paper  sx={{fontFamily: "inherit",width: "60%", backgroundColor: "#e17e7e"}}>
                        <Typography variant="h2" textAlign="center" sx={{fontSize: "2vh"}}>
                            I have done
                        </Typography>
                    </Paper>
                    {!boxStates[1] && <Box sx={{margin: 2, textAlign: "center", justifyContent: "center", animation: "fadeIn 1s ease-out"}}>
                        <Divider><Chip label="Work"/></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Saleswork, K-Rauta ~4 years
                        </Typography>
                        
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Uni Course assistant
                        </Typography>
                        <Divider><Chip label="Projects"/></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Usability testing report
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - UX design course
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - This website
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - PM work
                        </Typography>
                    </Box>}
                    {boxStates[1] && <Box sx={{textAlign: "center", justifyContent: "center", animation: "fadeIn 1s ease-out"}}>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            My longest lasting employment was in K-rauta Rauta-Otra Nekala from the years 2019-2022. I first worked a summer job there for the summer of 2019 and then worked along-side my studies. My employment involved a lot of customer service and shelving among other tasks in a typical brick and mortar store. I received commendable feedback for my work and made many happy customers.
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            I've done course assistant work at Tampere University for the Introduction to Software Engineering course in the Spring of 2024. This work included communicating with students taking the course and overseeing their project work.
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            On user experience I have done a couple projects. I've done a website usability evaluation project in which we evaluated the usability of the University SISU website, and conducted usability tests based on our observations. The report can be found HERE. I've also done some product design on a course which involved working on a project provided by Polar, and our design work was logged into a diary found HERE
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            On software engineering I have done multiple projects in University but also this website using React. This website serves as a community project that a handful of my friends also have access to develop. The source code of this site is private, but documentation on both the Hub and this personal website can be found HERE
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            On project working, I have worked on two of the software engineering project courses in the university. On the first one I worked as a junior developer to develop a skill display tool. On the second one I worked as a project manager doing a research-related VR navigation application for the University.
                        </Typography>
                    </Box>}
                </Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: boxStates[1] ? ("0vh") : ("5vh"),transition: "height 0.75s ease-in-out", backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: boxStates[1] ? ("0vh") : ("0vh"),transition: "height 0.75s ease-in-out", backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper onClick={handleIwant} elevation={10} sx={{fontFamily: "Playpen Sans, cursive",overflow: "auto",transition: "height 0.75s ease-in-out", height: boxStates[1] ? ("10vh") : (boxStates[3] ? ("65vh") : ("35vh")), backgroundColor: "#d9d9d9"}}>
                    <Paper sx={{width: "60%", backgroundColor:"#ef6565"}}>
                        <Typography variant="h2" textAlign="center" sx={{fontFamily: "inherit",fontSize: "2vh"}}>
                            I want to do
                        </Typography>
                    </Paper>
                    {!boxStates[3] && <Box sx={{textAlign: "center", justifyContent: "center", animation: "fadeIn 1s ease-out"}}>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            - More Web
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            - Front design
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            - Virtual Machines
                        </Typography>
                    </Box>}
                    {boxStates[3] && <Box sx={{textAlign: "center", justifyContent: "center", animation: "fadeIn 1s ease-out"}}>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            I want to learn more about Web related things. This website serves as a tool for that, and my latest progress can be found here.
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            I want to learn to design better interfaces using the latest libraries. On this aspect I have a lot more to improve on.
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "3vh"}}>
                            At some point in my career I want to know more about Linux, virtual machines and cloud applications. These three are and I believe will be the driving force of software development in the future.
                        </Typography>
                    </Box>}
                </Paper>
            </Grid2>
        </Grid2> 
    </Grid2>)
}

export default Column3