import { Typography, Card, CardContent } from "@mui/material"

const Titlecard = ({text1, text2}) => {

    return (<Card sx={{backgroundColor: "white", width: "60%",height: "45%", justifyContent: "center",textAlign: "center"}}>
        <CardContent>
        <Typography variant="h3" color="primary">
            {text1}
        </Typography>
        <Typography variant="h5" color="primary">
            {text2}
        </Typography>
        </CardContent>
    </Card>)

}


export default Titlecard
