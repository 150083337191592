import { contentDutch } from "./contentDutch";
import { contentRussian } from "./contentRussian";
import CardItem from "./CardItem";
import { useState, useEffect } from "react";
import { Card, CardContent, Grid, Box, Typography, CardActions, Button } from "@mui/material";

const Cards = ({language,started,correct, setCorrect, wrongs, setWrongs}) => {


    // Game variables
    const [phrases, setPhrases] = useState([["","",true],["","",false],["","",false],["","",false]])
    const [correctIndex, setCorrectIndex] = useState(0)

    // Determine answer correctness
    const handleAnswer = (index) =>{
        if(index === correctIndex) {
            setCorrect((correct+1))
        }
        else {
            setWrongs((wrongs + 1))
        }
        randomizeCards()
    }

    // Get the content of the four cards using indexes of the phrase list
    const getRandomIndexes = (array, count) => {
        const indexes = new Set(); 
    
        while (indexes.size < count) {
            const randomIndex = Math.floor(Math.random() * array.length);
            indexes.add(randomIndex);  
        }
    
        return Array.from(indexes); 
    }


    // Select random phrases using the phrase indexes
    const randomizeCards = () => {
        let searchLanguage = ""
            if(language[0]) {
                searchLanguage = contentDutch
            }
            else {  
                searchLanguage = contentRussian
            }   
            const randoms = getRandomIndexes(searchLanguage, 8)
            const newPhrases = [searchLanguage[randoms[0]],
                                searchLanguage[randoms[1]],
                                searchLanguage[randoms[2]], 
                                searchLanguage[randoms[3]]]
            let wrongs = [
                                searchLanguage[randoms[4]],
                                searchLanguage[randoms[5]],
                                searchLanguage[randoms[6]],
                                searchLanguage[randoms[7]]]
            const randomIndex = Math.floor(Math.random() * 4)
            setCorrectIndex(randomIndex)
            console.log(newPhrases)
            let outputPhrases = []
            for(let i = 0; i < newPhrases.length; i++) {

                if(i === randomIndex){
                    outputPhrases.push([newPhrases[i].phrase, newPhrases[i].trans,true])
                }
                else {
                    outputPhrases.push([newPhrases[i].phrase, wrongs[i].trans,false])
                }
            }
            setPhrases(outputPhrases)
            console.log(outputPhrases.length)
    }

    // Detect changes that start the game
    useEffect(() => {
        if(started) {
            randomizeCards()
        }

    }, [started, language])


    return (<Grid container sx={{display: "inline-flex",width: "35vw", height: "45vh", justifyContent: "center", textAlign: "center", boxSizing: "border-box"}}>
            {phrases.map((phrase, index) => (
                <Grid item xs={6} md={6} key={index} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                boxSizing: 'border-box',
                }}>
                <Card sx={{margin: 2,padding: 0, width: "25vw", height: "23vh", backgroundColor: "#CAAC54"}} onClick={() => handleAnswer(index)}>
                    <CardContent sx={{height: "30%", justifyContent: "center"}}>   
                        <Typography variant="h6" sx={{margin: 2}}>
                            Phrase
                        </Typography>
                    
                        {<CardItem phrase={phrase[0]}></CardItem>}
                    </CardContent>
                    <CardContent sx={{height: "30%"}}>
                        <Typography variant="h6" sx={{margin: 2}}>
                            Means
                        </Typography>
                        {<CardItem phrase={phrase[1]}></CardItem>}
                    </CardContent>
                </Card>
                </Grid>
            ))}
            </Grid>
        
            
    )
    
}

export default Cards