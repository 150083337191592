import PaperBack from "../../components/PaperBack"
import PaperContent from "../../components/PaperContent"
import { Button, Grid, FormControl,FormGroup,FormControlLabel,Checkbox,Typography, } from "@mui/material"
import { useState } from "react"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"

const Game = ({isSliding, setIsSliding, setGame}) => {

    // Game variable  to randomize
    const [random, setRandom] = useState([1,2])

    // Choose the game, either random or chosen by user
    const handleGameChoose = (item) => { 
        if(item === 0) {
            const randomized =  Math.floor(Math.random() * random.length);
            const randomGame = random[randomized]
            setGame(randomGame)
        }
        else  {
            setGame(item)
        }
        
        setIsSliding(2)
        



    }

    // Select games to add to randomizing pool
    const handleRandom = (item) => {
        if(random.indexOf(item) === -1) {
            const newList = [...random, item]
            setRandom(newList)
        }
        else {
            const newList = random.filter(find => find !== item)
            setRandom(newList)
        }
        
    }
    return(<div>
        <PaperBack isSliding={isSliding} order={1}>
            <PaperContent width={"100%"} height={"100%"}>
                <Grid item xs={6} md={6}>
                    <Typography marginBottom={5} variant="h4" color="primary">Choose game</Typography>        
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button onClick={() => handleGameChoose(1)} color="secondary" variant="contained" sx={{margin: 2}}>TNT</Button>
                    <Button onClick={() => handleGameChoose(2)} color="secondary" variant="contained" sx={{margin: 2}}>CNC</Button> 
                    {/* Not implemented, CNT */}
                    {/*<Button onClick={() => handleGameChoose(3)} color="secondary" variant="contained">CNT</Button>*/}
                </Grid>
            </PaperContent>                    
            <Grid item xs={6} md={6} margin={4}>
                <Typography variant="h1" color="primary">OR</Typography>        
            </Grid>
            <PaperContent width={"100%"} height={"100%"}>
                <Grid item xs={6} md={6}>
                    <Typography marginBottom={5} variant="h4" color="primary">Choose random</Typography>        
                </Grid>
                <Grid item xs={6} md={6}>
                    <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                        value="top"
                        control={<Checkbox defaultChecked onChange={(() => handleRandom(1))}/>}
                        label="TNT"
                        labelPlacement="top"
                        />
                        <FormControlLabel
                        value="top"
                        control={<Checkbox defaultChecked onChange={(() => handleRandom(2))}/>}
                        label="CNC"
                        labelPlacement="top"
                        />
                        {/* Not implemented, CNT */}
                        {/*<FormControlLabel
                        value="top"
                        control={<Checkbox  onChange={(() => handleRandom(3))}/>}
                        label="CNT"
                        labelPlacement="top"
                        />*/}
                    </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button disabled={!(random.length >= 2)} onClick={() => handleGameChoose(0)}>
                        Random
                        <ArrowForwardRoundedIcon/>
                    </Button>
                </Grid>
            </PaperContent>
        </PaperBack>             
    </div>)
}

export default Game