import { Paper, Box} from "@mui/material";

import Grid2 from '@mui/material/Grid2';
import  {bread} from "../../bread.js"

/*
 * Simple home page using Grid and Paper
 */

const Home = () => {
    return(<div>
        <Box sx={{ flexGrow:2}}>
        <Grid2 container spacing={6} sx={{display: "flex", justifyContent: "center",position: "relative", margin: 2, overflow: 'visible',wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', maxWidth: 'inherit', textAlign: "center", }}>
          <Grid2 size={2}>
            <Paper sx={{padding: 2, justifyContent: "center", textAlign: "center"}}>{bread.home}</Paper>
          </Grid2>
          <Grid2 size={2}>
            <Paper sx={{padding: 2, justifyContent: "center", textAlign: "center"}}>{bread.about}</Paper>
          </Grid2>
        </Grid2>
      </Box>

    </div>)

}

export default Home