import {Box,Paper, Fab, Grid2, Typography, CardContent, Card} from "@mui/material"
import { useState} from "react"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import StopIcon from '@mui/icons-material/Stop';
import GameBar from "./LanguageCards/GameBar";
import Cards from "./LanguageCards/Cards";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Titlecard from "../components/Titlecard";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


/*
 * Simple phrase matching card game. 
 * What is special: 
 *  - Attempt at more pleasing UI
 *  - Attempts at more componentified components with reuse in mind.
 */ 
const CardGame = () => {

    // Game handling variables, this game could've used some Redux
    const [started, setStarted] = useState(false)
    const [language, setLanguage] = useState([true, false])
    const [correct, setCorrect] = useState(1)
    const [wrongs, setWrongs] = useState(0)
    const [reset, setReset] = useState(false)
    const [paused, setPaused] = useState(false)


    const handleStart = () =>{
        setStarted(true)
        setPaused(true)
    }

    const handleReset = () => {
        setReset(!reset)
        setCorrect(1)
        setWrongs(0)
    }

    /*const handleBookmark = () => {
        console.log("handle bookmark")
    }*/

    const handlePause = () => {
        setPaused(!paused)
    }

    return(
        <Box sx={{justifyContent: "center", textAlign: "center", display:"flex", boxSizing: "border-box"}}>
        <Paper elevation={8} sx={{display: "flex", width: "60vw", height: "80vh", backgroundColor: "transparent", margin: 2, padding: 2, justifyContent: "center", textAlign: "center"}}>
            <Grid2 container sx={{display: "flex", justifyContent: "center", textAlign: "center", boxSizing: "border-box"}}>
                <Grid2 size={12}>

                    {/* Appbar that contains game related information and selections */}
                    <GameBar language={language} setLanguage={setLanguage} started={started} correct={correct} reset={reset} paused={paused} wrongs={wrongs}/>
                </Grid2>
                {!started && 
                    <Grid2 size={12} sx={{justifyContent: "center",textAlign: "center", display:"flex"}}>

                        {/* Custom titlecard */}
                        <Titlecard text1={"Welcome to the phrases cardgame!"} 
                                   text2={"Select the card with the correct translation"}></Titlecard>
                    </Grid2>}
                {!started && <Grid2 size={12}>
                    <Fab color="primary" sx={{width: 120, height: 120, fontSize: "25px"}} onClick={handleStart}>
                        {language[0] && <div>Begin</div>}
                        {language[1] && <div>Начать</div>}
                    </Fab>
                </Grid2>}
                {started && <Grid2 size={12}>
                    <Cards language={language} started={started} correct={correct} setCorrect={setCorrect} wrongs={wrongs} setWrongs={setWrongs}></Cards>
                </Grid2>}
                
                
                
                {started && <Grid2 size={12}>
                    <Fab color="secondary" size="large" sx={{margin: 3}} onClick={handleReset}>
                        <RestartAltIcon/>
                    </Fab>
                    <Fab color="primary" size="large" sx={{margin : 3}} onClick={handlePause}>
                        {paused && <StopIcon/>}
                        {!paused && <PlayArrowIcon/>}
                    </Fab>

                     {/*<Fab color="secondary" size="large" sx={{margin: 1}} onClick={handleBookmark}>
                        <BookmarkIcon/>
                    </Fab>*/}
                    
                </Grid2>}
                
                
                    
            </Grid2>
        </Paper>
        </Box>
    
    )
}

export default CardGame