export const contentDutch = [
    { phrase: "Hallo", trans: "Hello" },
    { phrase: "Hoi", trans: "Hi" },
    { phrase: "Goedemorgen", trans: "Good morning" },
    { phrase: "Goedenmiddag", trans: "Good afternoon" },
    { phrase: "Goedenavond", trans: "Good evening" },
    { phrase: "Dag", trans: "Bye" },
    { phrase: "Tot ziens", trans: "See you later" },
    { phrase: "Dank u wel", trans: "Thank You very much" },
    { phrase: "Dank je wel", trans: "Thanks" },
    { phrase: "Alstublieft", trans: "You're welcome" },
    { phrase: "Alsjeblieft", trans: "Please" },
    { phrase: "Pardon", trans: "Excuse me" },
    { phrase: "Mevrouw", trans: "Madam" },
    { phrase: "Meneer", trans: "Sir" },
    { phrase: "Sorry", trans: "Sorry" },
    { phrase: "Hoe gaat het met jou?", trans: "How are you?" },
    { phrase: "Wat is je naam?", trans: "What’s your name?" },
    { phrase: "Mijn naam is", trans: "My name is" },
    { phrase: "Aangenaam", trans: "Nice to meet you" },
    { phrase: "Waar kom je vandaan?", trans: "Where are you from?" },
    { phrase: "Hoe oud ben jij?", trans: "How old are you?" },
    { phrase: "Kunt u wat langzamer praten?", trans: "Could you talk a bit slower?" },
    { phrase: "Kunt u dat herhalen?", trans: "Could you repeat that please?" },
    { phrase: "Spreekt u Engels?", trans: "Do you speak English?" },
    { phrase: "Ik wil graag", trans: "I’d like" },
    { phrase: "Mag ik een?", trans: "May I have a?" },
    { phrase: "Nog een bier, graag.", trans: "Another beer, please." },
    { phrase: "Hebben jullie een engelstalige menu?", trans: "Do you have an English menu?" },
    { phrase: "Waar is de WC/het toilet?", trans: "Where is the restroom?" },
    { phrase: "De rekening, graag.", trans: "Check, please." }
];