import { Button } from "@mui/material"

/*
* Redirects to a page using a button, same as Navigate but for whole window reload
*/ 

const Redirect = ({url, title, children}) => {

    const handleRedirect = () => {
        window.location.href = url  
    }

    return(
        <div>
            <Button onClick={handleRedirect} variant="contained" color="primary" sx={{ margin: 0.2,borderWidth: '2px', borderColor: 'secondary.main'}} >
                {children}{title}
            </Button>
        </div>
    )
}

export default Redirect