import { Paper,Grid } from "@mui/material"
const PaperContent = ({width, height, children}) => {
    return(
        <Grid item xs={6} md={6}>
        <Paper elevation={6} sx={{width: {width} ,height: {height}, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, boxSizing: "border-box", position: "relative"}}>
            <Grid container direction="column" sx={{ display: 'flex', alignItems: 'center' }}>
                {children}
            </Grid>
        </Paper>
        </Grid>)
}

export default PaperContent