
import Memgame from "./sites/Memgame"
import GMTApp from "./sites/GMTApp"
import CardGame from "./sites/CardGame"
import Home from "./sites/Home"
import { Route,Routes } from "react-router-dom";
import NavBar from "./components/NavBar"
import PostWall from "./sites/PostWall";
import CVPage from "./sites/CVPage";
import TrainingCards from "./sites/TrainingCards";

/*
 * App that is loaded when entering niko.tyomaa.eu
 */

function App() {

  return (
    <div className="App">
      <NavBar/>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/memgame" element={<Memgame/>}/>
        <Route path="/cardgame" element={<CardGame/>}/>
        <Route path="/gmtapp" element={<GMTApp/>}/>
        <Route path="/postwall" element={<PostWall/>}/>
        <Route path="/cvwall" element={<CVPage/>}/>
        <Route path="/trainingcards" element={<TrainingCards/>}/>
      </Routes>
    </div>
  );
}

export default App;
