import PaperBack from "../../components/PaperBack"
import PaperContent from "../../components/PaperContent"
import { Grid, Typography, Box, TextField, FormControl, FormGroup, Checkbox, Button } from "@mui/material"

import { useState } from "react"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"
import WbTwilightIcon from "@mui/icons-material/WbTwilight"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"
import ConstructionIcon from "@mui/icons-material/Construction"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"


const Countries = ({isSliding, setIsSliding, game, setCountries, playerNames, setPlayerNames,randomPlayers, setRandomPlayers}) => {

    // Choose countries based on game 
    const inputCountries = () => {
        if(game===1) {
            setCountries(["Germany", "Great Britain", "USSR"])
        }
        else if (game===2) {
            setCountries(["Japan", "USA", "Soviets"])
        }
        /*
        else {
            setCountries(["Germany", "Japan", "Great Britain", "USA", "USSR", "Soviets"])
        }*/
        setIsSliding(3)
        setPlayerNames(randomPlayers)
    }

    return(<div>
        <PaperBack isSliding={isSliding} order={2}>
        
        <PaperContent width={"100%"} height={"100%"}>
        <Grid item xs={6} md={6}>
            <Typography marginBottom={5} variant="h3" color="primary">{game===1?("Triumph & Tragedy"):(game===2?("Conquest & Consequence"):("Conquest & Tragedy"))}</Typography>        
        </Grid>  
            <Grid item xs={6} md={6}>
                <Typography variant="h4" color="primary">Choose factions</Typography>        
            </Grid>
            <Grid item xs={6} md={6}>
                <Typography marginBottom={5} variant="body1" color="primary">(leave empty for random)</Typography>        
            </Grid>
            {game !== 3 && <Grid item xs={6} md={6}>
                <FormControl component="fieldset">
                <FormGroup aria-label="position">
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: 2 }}>
                    {game===1?
                    (<LocalHospitalIcon sx={{ color: 'action.active', mr: 2, my: 2 }} />):
                    (<WbTwilightIcon sx={{ color: 'action.active',  mr: 2, my: 2  }} />)}
                    <TextField
                    label={game===1?("Germany"):("Japan")}
                    width={2}
                    inputProps={{ maxLength: 2 }}
                    onChange={(event) => {
                        setRandomPlayers((prevPlayers) => [event.target.value, prevPlayers[1], prevPlayers[2]]);
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: 2  }}>
                {game===1?
                    (<LocalCafeIcon sx={{ color: 'action.active',  mr: 2, my: 2 }} />):
                    (<StarBorderIcon sx={{ color: 'action.active',  mr: 2, my: 2  }} />)}
                    <TextField
                    label={game===1?("Great Britain"):("USA")}
                    width={2}
                    inputProps={{ maxLength: 2 }}
                    onChange={(event) => {
                        setRandomPlayers((prevPlayers) => [prevPlayers[0],event.target.value,  prevPlayers[2]]);
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: 2  }}>
                {game===1?
                    (<ConstructionIcon sx={{ color: 'action.active',  mr: 2, my: 2  }} />):
                    (<ConstructionIcon sx={{ color: 'action.active',  mr: 2, my: 2  }} />)}
                    <TextField
                    label={game===1?("USSR"):("Soviets")}
                    width={2}
                    inputProps={{ maxLength: 2 }}
                    onChange={(event) => {
                        setRandomPlayers((prevPlayers) => [prevPlayers[0], prevPlayers[1],event.target.value]);
                        }}
                    />
                </Box>
                    
                    
                    
                </FormGroup>
                </FormControl>

            </Grid>}
            {/* Not implemented, CNT */}
            {/*game === 3 && <Grid item xs={6} md={6}>
                <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <TextField
                    value="top"
                    control={<Checkbox />}
                    label="TNT"
                    labelPlacement="top"
                    />
                    <TextField
                    value="top"
                    control={<Checkbox />}
                    label="CNC"
                    labelPlacement="top"
                    />
                    <TextField
                    value="top"
                    control={<Checkbox />}
                    label="CNT"
                    labelPlacement="top"
                    />
                </FormGroup>
                </FormControl>

            </Grid>*/}
            <Grid item xs={6} md={6}>
                <Button onClick={inputCountries}>
                    Create game
                    <ArrowForwardRoundedIcon/>
                </Button>
            </Grid>
            </PaperContent>
        </PaperBack>
    </div>)
}

export default Countries