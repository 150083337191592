import { Grid, Typography, Button } from "@mui/material"

import PaperBack from "../../components/PaperBack"
import PaperContent from "../../components/PaperContent"

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded"

const Start = ({isSliding, setIsSliding}) => {

    return(<div>
        <PaperBack isSliding={isSliding} order={0}>
            <PaperContent>
            <Grid item xs={6} md={6} margin={4}>
                <Typography variant="h3" color="primary">GMT Game Aligner</Typography>        
            </Grid>
            <Grid item xs={6} md={6}>
                <Button onClick={() => setIsSliding(1)} size="large" variant="contained" color="secondary">
                    Go to Joe 
                    <ArrowForwardRoundedIcon/>
                </Button>
            </Grid>
            <Grid item xs={6} md={6} margin={3}>
                
                <Button onClick={() => setIsSliding(-1)} size="large" variant="text" color="secondary">
                    <ArrowBackRoundedIcon/>
                    Just the rules thanks
                    
                </Button>
            </Grid>
            </PaperContent>
        </PaperBack>
    </div>)
}

export default Start