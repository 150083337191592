import { useState, useEffect } from "react"
import { Grid, Grid2, Typography } from "@mui/material"


const Stopwatch = ({correct,reset,paused, wrongs}) => {

    // Time variable
    const [time, setTime] = useState(0)

    // Time formatting
    const formatTime = (time) => {
        const getSeconds = `0${time % 60}`.slice(-2);
        const minutes = Math.floor(time / 60);
        const getMinutes = `0${minutes % 60}`.slice(-2);
        const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

        return `${getHours}:${getMinutes}:${getSeconds}`;
    }

    // Timer logic
    useEffect(() => {
        let interval = null;

        if (paused) {
            interval = setInterval(() => {
            setTime((prevTime) => prevTime + 1); // Increment time by 1 every second
            }, 1000);
        } else if (!paused && time !== 0) {
            clearInterval(interval); // Clear the interval if the stopwatch is paused
        }
        return () => clearInterval(interval);
    }, [time, paused])


    // Detect reset using useEffect    
    useEffect(() => {
        setTime(0)
    }, [reset])



    return(<Grid2 container columns={3} sx={{}}>

        <Grid2 size={4}>
            <Typography variant="h6" color="initial">{formatTime(time)} <br/></Typography>
        </Grid2>
        <Grid2 size={4}>
            <Typography variant="h6" color="initial">Correct: {correct-1} </Typography>
            
        </Grid2>
        <Grid2 size={4}>
            <Typography variant="h3" color="initial">Average: {(Math.round(time/(Math.max(1, correct - wrongs))*10)/10)}</Typography>
            
        </Grid2>
        </Grid2>
        
    )
}

export default Stopwatch