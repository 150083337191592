import { Grid2, Box, Paper, Typography } from "@mui/material"

import { useState } from "react"
import "../../../styles/slide.css"
import Column1 from "./Content/Column1"
import Column2 from "./Content/Column2"
import Column3 from "./Content/Column3"

const CVContent = () => {

    // Page variables
    const [boxStates, setBoxStates] = useState([false,false,false,false])
    const [flip, setFlip] = useState(0)

    
    // Disgusting handler functions, can be improved UNIFY
    const handleFlip = () => {
        setFlip(flip+1)
        
    }
    const handleIam = () => {
        if(boxStates[0]) {
            setBoxStates([false,false,false,false])
        }
        else {
            setBoxStates([true,false,false,false])
        }
        
    }
    const handleIhave = () => {
        if(boxStates[1]) {
            setBoxStates([false,false,false,false])
        }
        else {
            setBoxStates([false,true,false,false])
        }
    }
    const handleIcan = () => {
        if(boxStates[2]) {
            setBoxStates([false,false,false,false])
        }
        else {
            setBoxStates([false,false,true,false])
        }
    }
    const handleIwant = () => {
        if(boxStates[3]) {
            setBoxStates([false,false,false,false])
        }
        else {
            setBoxStates([false,false,false,true])
        }
    }
    

return (
    <Grid2 size={12} sx={{width: {xs: "95vw",sm: "80vw", md: "60vw"}, height: "100vh"}}>
        <Box sx={{overflow:"auto"}}>
            <Grid2 container columns={{xs:2, sm: 4, md: 6}} spacing={5} sx={{padding: 0, margin: 4, rows: 5, overflow:"hidden"}}>

            {/* I am & I can boxes, just a lot of content*/}
            <Column1 boxStates={boxStates} handleIam={handleIam} handleIcan={handleIcan}></Column1>
            {/* Image box, just a lot of content*/}
            <Column2 boxStates={boxStates} flip={flip} handleFlip={handleFlip}></Column2>
            {/* I have & I want boxes, just a lot of content*/}
            <Column3 boxStates={boxStates} handleIhave={handleIhave} handleIwant={handleIwant}></Column3>     

            </Grid2>
        </Box>
    </Grid2>
)
}

export default CVContent