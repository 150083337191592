
import { Typography } from "@mui/material"

const CardItem = ({phrase}) => {

    return (
        <Typography variant="h5" color="black">{phrase}</Typography>
    )
    
}

export default CardItem