import { Paper } from "@mui/material"

import { useDispatch} from "react-redux"
import { useSelector } from "react-redux"
import { handleMemgameLogic } from "../../../Controller/memgame"


const MemoryCard = ({image}) => {
    //Images store
    const images = useSelector((state) => state.image)

    // Notifications store
    const checkable = useSelector(state => state.notification)
    const dispatch = useDispatch()
    return(

    <div>    

        <Paper
        onClick={() => {
            //Check notifications array to determine if player can currently interact with the game
            if(checkable.length === 0 && image.hidden === true) {
                handleMemgameLogic(dispatch, image, images) 
            }
            
        }}
        elevation={4}
        sx={{padding: 0.5, margin: 1,width: "10vh", height: "10vh", justifyContent: "center", alignItems: "center", display: "flex"}}>
            <img
            hidden={image.hidden}
            src={image.info.url}
            width="100%"
            height="100%"
            alt={image.info.alt}
            />   
        </Paper>
    </div>)
}

export default MemoryCard