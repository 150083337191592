
import { Grid2, Typography } from "@mui/material"
import PostCard from "./PostCard"

// WILL BE IMPLEMENTED AFTER SUBMISSION (DUE TO BEING MORE OF A BACKEND MARVEL)

const Archive = ({posts}) => {

    return (
    <div>
        <Grid2 container spacing={6} sx={{display: "inline-flex", justifyContent: "center", margin: 2 }}>
        
        {posts.map(post => (
            <Grid2 size={2}>
                {//<PostCard post={post}/>
}
                <Typography>{post.title}</Typography>
                <Typography>{post.content}</Typography>
            </Grid2>
        ))}
        </Grid2>
    </div>
    )
}

export default Archive