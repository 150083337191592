import PaperBack from "../../components/PaperBack"
import PaperContent from "../../components/PaperContent"
import { Grid,Typography, Link,  } from "@mui/material"
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded"
import { useState, useEffect } from "react"

import { rules } from "./ruleAssets"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"
import WbTwilightIcon from "@mui/icons-material/WbTwilight"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"
import ConstructionIcon from "@mui/icons-material/Construction"

const GameSummary = ({isSliding, game, gameDetails}) => {

    // Fancy final page step-by-step reveal things
    const [visible, setVisible] = useState([false, false, false, false, false]);
    useEffect(() => {
        if (isSliding === 4) {
            const timers = [];
            for (let i = 0; i < 5; i++) {
                timers.push(setTimeout(() => {
                    setVisible(prevVisible => {
                        const newVisible = [...prevVisible];
                        newVisible[i] = true;
                        return newVisible;
                    });
                }, i * 2000)); 
            }

            // Cleanup timers on component unmount
            return () => timers.forEach(timer => clearTimeout(timer));
        }
    }, [isSliding]);


    return(<div>
        <PaperBack isSliding={isSliding} order={4}>
        
        <PaperContent width={"40vw"} height={"70vh"}>   
        <Grid item xs={6} md={6}>
        <Typography marginBottom={5} variant="h3" color="primary">{game===1?("Triumph & Tragedy"):(game===2?("Conquest & Consequence"):("Conquest & Tragedy"))}</Typography>        
        </Grid>
                    
            {visible[0] && <Grid item xs={6} md={6} className="fade-in">
                <Typography variant="h4" color="primary" margin={3}>Here is your game</Typography>        
            </Grid>}
            {visible[1] && <Grid item xs={6} md={6} className="fade-in">
                <Typography variant="h4" color="primary" margin={3}>{gameDetails[0] + ": " + gameDetails[1] } </Typography>        
            </Grid>}
            {visible[2] && <Grid item xs={6} md={6} className="fade-in">
                <Typography variant="h4" color="primary" margin={3}>{gameDetails[2] + ": " + gameDetails[3] }</Typography>        
            </Grid>}
            {visible[3] && <Grid item xs={6} md={6} className="fade-in">
                <Typography variant="h4" color="primary" margin={3}> {gameDetails[4] + ": " + gameDetails[5] }</Typography>        
            </Grid>}



            {visible[4] && 
            <div>
            <Grid container direction="column" sx={{ display: 'flex', alignItems: 'center' }}>
                {rules.map((item) => (
                    <Grid item xs={6} md={6} className="fade-in" key={item.text}>
                    <Link href={item.url}
                        underline="hover"
                        target="_blank" 
                        rel="noopener noreferrer">
                        {item.text}
                        <ArrowForwardRoundedIcon/>
                    </Link>
                </Grid>
                ))}
            </Grid>
            </div>
            }
        </PaperContent>
        </PaperBack>             
        
    </div>)
}

export default GameSummary