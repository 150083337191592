import { Paper,Button, Grid2, Box, AppBar, Select, FormControl, InputLabel, MenuItem,FormHelperText } from "@mui/material"
import { useEffect, useState } from "react"
import WordCard from "./TrainingCards/WordCard"
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { contentRussianPhrases } from "./TrainingCards/WordPresets/contentRussianPhrases";
import { contentRussianSlang } from "./TrainingCards/WordPresets/contentRussianSlang";
import { contentRussianVerbs } from "./TrainingCards/WordPresets/contentRussianVerbs";
import { contentRussianNouns } from "./TrainingCards/WordPresets/contentRussianNouns";
import { contentRussianConvos } from "./TrainingCards/WordPresets/contentRussianConvos";
import { isMobile } from "react-device-detect";

/*
 * Simple flashcards for language training, currently only in Russian, used on the Russian course for a personal dictionary
 * What is special: 
 *  - Attempts at being Mobile friendly (nothing else is quite there with this site, will be done later after submission)
 *  - Better UI with more interaction and options
 */ 

const TrainingCards = () => {

    const [showTrans, setShowTrans] = useState(true)
    const [showPhrase, setShowPhrase] = useState(true)
    const [listOptions, setListOptions] = useState([contentRussianPhrases, contentRussianSlang, contentRussianVerbs, contentRussianNouns, contentRussianConvos])
    const [content,setContent] = useState(listOptions[0])
    const [contentName,setContentName] = useState(0)

    
    const handleTrans = () => {
        setShowTrans(!showTrans)
    }
    const handlePhrase = () => {
        setShowPhrase(!showPhrase)
    }
    const handleSelect = (event) => {
        setContent(listOptions[event.target.value])
        setContentName(event.target.value)
    };
    



    return (
    <Box sx={{justifyContent: "center", textAlign: "center", display:"flex"}}>
        <Paper elevation={8} sx={{width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" }, height: "90vh", backgroundColor: "transparent", margin: 2, padding: 2, overflow:"auto"}}>
        <AppBar sx={{position:"relative", justifyContent:"center",backgroundColor: "#9A365C",padding: 1}}>
            <Grid2 container spacing={0} sx={{display: "flex", justifyContent:"center",}}>
                <Grid2 size={{ xs: 5, md:5 }}>
                    <Button onClick={handlePhrase} variant="contained" color="secondary" sx={{margin: 2, fontSize: {xs: 10, md: 30 }}}>{showPhrase ? "Hide Phrases" : "Show Phrases"}</Button>
                </Grid2>
                <Grid2 size="auto">
                {/* Desktop consideration */}
                {!isMobile && <FormControl variant="standard" sx={{minWidth: 120 }}>
                    <InputLabel sx={{ color: 'white' }} >Wordlists</InputLabel>
                    <Select
                        value={contentName}
                        label="Wordlists"
                        onChange={handleSelect}
                        variant="filled"
                        sx={{ color: 'white' }}
                        
                    >
                        <MenuItem value={0}>Phrases</MenuItem>
                        <MenuItem value={1}>Slang</MenuItem>
                        <MenuItem value={2}>Verbs</MenuItem>
                        <MenuItem value={3}>Nouns</MenuItem>
                        <MenuItem value={4}>Convos</MenuItem>
                    </Select>
                    </FormControl>}
                    </Grid2>
                <Grid2 size={{ xs: 5, md: 5 }}>
                    <Button onClick={handleTrans} variant="contained" color="secondary" sx={{margin: 2, fontSize: {xs: 10, md: 30 }}}>{showTrans ? "Hide Translations" : "Show Translations"}</Button>
                </Grid2>
                
            </Grid2>
            {/* Mobile consideration */}
            {isMobile && <FormControl variant="standard" sx={{minWidth: 120 }}>
                    <InputLabel sx={{ color: 'white' }} >Wordlists</InputLabel>
                    <Select
                        value={contentName}
                        label="Wordlists"
                        onChange={handleSelect}
                        variant="filled"
                        sx={{ color: 'white' }}
                        
                    >
                        <MenuItem value={0}>Phrases</MenuItem>
                        <MenuItem value={1}>Slang</MenuItem>
                        <MenuItem value={2}>Verbs</MenuItem>
                        <MenuItem value={3}>Nouns</MenuItem>
                        <MenuItem value={4}>Convos</MenuItem>
                    </Select>
                    </FormControl>}
            </AppBar>
            

            
            
            
            {content.map((content, index) => (
                <Grid2 key={index} container sx={{display: "flex", justifyContent:"center",alignItems:"center", }}>
                    <Grid2 size={{ xs: 1, md: 5 }} sx={{marginTop: 2,flexGrow: 1}}>
                        {/* Custom Wordcard */}
                        <WordCard showContent={showPhrase} content={content.phrase}/>
                    </Grid2>
                    <Grid2 size="auto" sx={{margin: "4px", marginTop: 2}}>
                        <Paper elevation={0} sx={{padding: 0, margin: 0, backgroundColor: "#CAAC54",}}>
                            <DoubleArrowIcon sx={{fontSize: 'calc(0.8 * min(5vh, 20vw))'}}/>
                        </Paper>
                    </Grid2>
                    <Grid2 size={{ xs: 1, md: 5 }} sx={{marginTop: 2,flexGrow: 1}}>
                        {/* Custom Wordcard */}
                        <WordCard showContent={showTrans} content={content.trans}/>
                    </Grid2>
                </Grid2>
                
            ))}

            </Paper>
            </Box>
    )

}

export default TrainingCards