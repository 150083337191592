import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../constants"

export const setNotification = (status) => {

    return {
        type: ADD_NOTIFICATION,
        payload : status
    }

}


export const removeNotification = (category) => {

    return {
        type: REMOVE_NOTIFICATION,
        payload: category
    }

}
