import { Grid2,Paper,Typography,Box,Divider,Chip  } from "@mui/material"

const Column1 = ({boxStates, handleIam, handleIcan}) => {
    return(<Grid2 size={(boxStates[1] || boxStates[3]) ? 
        (1) : ((boxStates[0] || boxStates[2])  ?
         (4) : (2))} 
         sx={{transition: "width 0.75s ease-in-out"}} >
        <Grid2 container direction="column">
            <Grid2 size="grow">
                <Paper onClick={handleIam} elevation={10} sx={{fontFamily: "Playpen Sans, cursive", overflow: "auto",transition: "height 0.75s ease-in-out",height: boxStates[2] ? ("10vh") : (boxStates[0] ? ("70vh") : ("35vh")) , backgroundColor: "#d9d9d9"}}>
                    <Paper sx={{width: "60%", backgroundColor: "#ffa7a7"}}>
                        <Typography variant="h2" textAlign="center" sx={{fontFamily: "inherit",fontSize: "3vh"}}>
                            I am
                        </Typography>
                    </Paper>
                    {!boxStates[0] && <Box sx={{margin: 2,textAlign: "center", animation: "fadeIn 1s ease-out"}}>
                        <Divider><Chip label="School" /></Divider>
                        <Typography variant="h5" sx={{ fontFamily: "inherit", margin: 1, fontSize: "1.5vh"}}>
                            - Master's student, Tampere University, Computer Science
                        </Typography>
                        <Divider><Chip label="Strenghts"/></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Positive, Helpful, Responsible, Flexible
                        </Typography >
                        <Divider><Chip label="Interests"/></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Web, Usability, Virtualization
                        </Typography>
                    </Box>}
                    {boxStates[0] && <Box sx={{textAlign: "center", animation: "fadeIn 1s ease-in"}}>
                    
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I am a 24 year old Master's student doing my 6th and final year in Tampere University in the Information technology faculty on Computer Science. I also have complementary studies in Human technology interaction  
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I completed Bachelor's degree in Tampere University in Fall 2023
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I graduated from Tampere classical upper secondary school in 2019
                        </Typography >
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                           I have a GPA of 4.30 in my Master's studies, my study report card is HERE
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I am very objective-driven with responsibility capabilities. I thrive in well-organized environments, and I appreciate optimisation of workflow and constantly moving forward.
                        </Typography>
                    </Box>}
                </Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: boxStates[2] ? ("5vh") : ("0vh"), backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: boxStates[2] ? ("0vh") : ("5vh"), backgroundColor:"transparent"}}></Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper onClick={handleIcan} elevation={10} sx={{fontFamily: "Playpen Sans, cursive",overflow: "auto",transition: "height 0.75s ease-in-out",height: boxStates[0] ? ("10vh") : (boxStates[2] ? ("70vh") : ("35vh")), backgroundColor: "#d9d9d9" }}>
                    <Paper sx={{width: "60%", backgroundColor: "#dd6161"}}>
                        
                    <Typography variant="h2" textAlign="center" sx={{fontFamily: "inherit",fontSize: "2vh"}}>
                            I can do
                    </Typography>
                    </Paper>

                    {!boxStates[2] && <Box sx={{margin: 2,textAlign: "center", justifyContent: "center", animation: "fadeIn 1s ease-out"}}>
                    <Divider><Chip label="Platforms" /></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Arch Linux, Mac, Windows
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Virtual machines
                        </Typography>
                    <Divider><Chip label="Programming" /></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - React, libraries
                        </Typography>
                        
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - OOP, FP
                        </Typography>
                    <Divider><Chip label="Other" /></Divider>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Design applications
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Fin, Eng, Rus, Nld
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily: "inherit",margin: 1, fontSize: "1.5vh"}}>
                            - Teamwork
                        </Typography>
                    </Box>}
                    {boxStates[2] && <Box sx={{textAlign: "center", animation: "fadeIn 1s ease-in"}}>
                    
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I've mostly worked with React, building this website and a couple of other applications using it. In addition, I've used MatterJS for physics, cytoscapeJS for node graphs. I also have experience building Java applications. 
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            From other OOP langauges I've used C. I'm also familiar with Functional programming using Haskell and Erlang
                        </Typography >
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            I daily drive Arch-based Manjaro Linux, but I'm also familiar with Mac and Windows environments. I've chosen Arch since I wanted to experiment with QEMU/KVM virtual machines using GPU passthrough to achieve a more expansive personal computer experience. I'm also familiar with dockerization. 
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                           I also have some UI/UX experience using design applications like Figma and draw.io to design interfaces and products.
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            My native language is Finnish, although I also speak fluent English. I also have communication skills in Russian and Dutch on which I'm currently actively working on.
                        </Typography>
                        <Typography variant="h5" sx={{margin: 1, fontSize: "1.5vh"}}>
                            Regarding people skills, I've garnered quite a lot of experience through work and university situations. I can assume a variety of positions in a Team and have no issue with taking a more leading position.
                        </Typography>
                    </Box>}


                </Paper>
            </Grid2>
            <Grid2 size="grow">
                <Paper elevation={0} sx={{height: boxStates[0] ? ("5vh") : ("10vh"), backgroundColor:"transparent"}}></Paper>
            </Grid2>
        </Grid2> 
    </Grid2>)
}

export default Column1