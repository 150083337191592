import "../../styles/slide.css"
import { Paper,Box,Grid } from "@mui/material"

const PaperBack = ({isSliding, order, children}) => {
    return(
    <Box className={`sliding-container ${ 
        isSliding === order?
            ('slide-center') 
            :(isSliding < order? 
                ('slide-incoming')
                :('slide-offscreen'))}`} 
             sx={{ 
                 position: 'absolute', 
                 width: '100%', 
                 height: '100%', 
                 display: 'flex', 
                 justifyContent: 'center', 
                 alignItems: 'center',
                 transition: 'transform 1.5s ease-in-out'
             }}>
            <Paper elevation={0} sx={{ width: "60vw", height: "85vh", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 10, padding: 2, boxSizing: "border-box", backgroundColor: "transparent", position: "relative" }}>
                <Grid container direction="column" sx={{ display: 'flex', alignItems: 'center', position: "relative" }}>
                    {children}
                </Grid>
            </Paper>
        </Box>)
}

export default PaperBack