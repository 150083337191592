import { SHOW_IMAGES, LOAD_IMAGES, SHUFFLE_IMAGES, HIDE_IMAGES } from "../constants"
import { v4 as uuidv4 } from "uuid"

export const showImages = (image) => {
    return {
        type: SHOW_IMAGES,
        payload: image
    }
}

export const loadImages = (images) => {
    
    return {
        type: LOAD_IMAGES,
        payload: images
    }
}

export const shuffleImages = (images) => {
    return {
        type: SHUFFLE_IMAGES,
        payload: images
    }
}

export const hideImages = () => {

    return  {

        type: HIDE_IMAGES,
        payload: ""
    }
}