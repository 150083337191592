import { AppBar, Toolbar,Divider,Typography, Paper, Grid  } from "@mui/material"
import Sidebar from "./Sidebar"
import Redirect from "./Redirect"
import urlTable from "../../urlTable.js"
import { Link } from "react-router-dom"
import PaperContent from "./PaperContent.jsx"
import Navigate from "./Navigate.jsx"

import HomeIcon from '@mui/icons-material/Home';
import HubIcon from '@mui/icons-material/Hub';

/*
 * This is kinda empty atm, since for the submission I refrained from creating a lot of static content
 */ 

const NavBar = () => {
    
    return(<div>
        <AppBar className="App-header" position="static" sx={{backgroundColor: 'transparent'}}>
        <Toolbar>
        
        <Sidebar/>
        
        
        
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Redirect url={"/"} title={""}><HomeIcon/></Redirect>
          </Typography>
          
          
          <Redirect url={urlTable.hub} title={""}><HubIcon/></Redirect>
          
          
        </Toolbar>
      </AppBar>
    </div>)

}

export default NavBar