import { Grid2, Paper, Box, Typography } from "@mui/material"
import arrow1 from "../../../../assets/arrow1.png"

const CVHeader = () => {
    return (
        <Grid2 size={12}>
            <Paper elevation={6} sx={{width: {xs: "95vw",sm: "80vw", md: "60vw"}, height: "15vh", backgroundColor:"#e75a5a", boxSizing: "border-box"}}>
                <Grid2 container>

               
                    <Paper elevation={4} sx={{ position: "left", width: "20vw",height: "15vh", backgroundColor: "#c06a6a"}}>
                    <Box textAlign="center">
                        <Typography variant="h1" sx={{fontSize: "3vh"}}>
                            Niko Väkiparta
                        </Typography>
                        <Typography variant="h4" sx={{fontSize: "3vh"}}>
                            CV
                        </Typography>
                        <Typography variant="h5" sx={{fontSize: "3vh"}}>
                            13.9.2024
                        </Typography>
                    </Box>
                    </Paper>
                    <Box sx={{width: "10vw", height: "15vh", justifyContent: "center", alignItems:"center", display: "flex"}}>
                        <img
                            src={arrow1}
                            width="80%"
                            height="90%"
                        />

                    </Box>
                    <Box sx={{width: "30vw", height: "15vh"}}>

                    </Box>
                </Grid2>
            </Paper>
        </Grid2>
    )
   
}

export default CVHeader