import { Grid2, Paper, Box, Typography } from "@mui/material"
import arrow2 from "../../../../assets/arrow2.png"

const CVFooter = () => {
    return (
        <Grid2 size={12}>
            <Paper elevation={6} sx={{width: {xs: "95vw",sm: "80vw", md: "60vw"}, height: "15vh", backgroundColor:"#e75a5a"}}>
                <Grid2 container>
                    <Box sx={{position: "left", width: "30vw",height: "15vh"}}>

                    </Box>
                    <Box sx={{width: "10vw", height: "15vh", justifyContent: "center", alignItems:"center", display: "flex"}}>
                    <img
                        src={arrow2}
                        width="80%"
                        height="90%"
                    />
                    </Box>
                    <Paper elevation={4} sx={{position: "right", width: "20vw",height: "15vh", backgroundColor: "#c06a6a"}}>
                        <Box textAlign="center">

                        <Typography variant="h1" sx={{fontSize: "3vh"}}>
                            Niko Väkiparta
                        </Typography>
                        <Typography variant="body1" sx={{fontSize: "2vh"}}>
                            PHONE NUMBER (SET AS VIEWABLE WITH SECRET)
                        </Typography>
                        <Typography variant="body1" sx={{fontSize: "2vh"}}>
                            EMAIL (SET AS VIEWABLE WITH SECRET)
                        </Typography>
                        </Box>
                    
                </Paper>
                </Grid2>
            </Paper>
        </Grid2>
    )
   
}

export default CVFooter