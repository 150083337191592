import PaperBack from "../../components/PaperBack";
import PaperContent from "../../components/PaperContent";
import { Grid, Typography, TextField, FormGroup, FormControl, Button } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useState, useEffect, useRef } from "react";

const Players = ({isSliding, setIsSliding, game, playerNames, setPlayerNames, randomPlayers}) => {

    // Player variables
    const playerRef = [useRef(null),useRef(null),useRef(null)];
    const [resetTime, setResetTime] = useState(false)

    const handleFocus= (index, event) => {
        const newNames = [...playerNames]
        newNames[index] = event.target.value
        setPlayerNames(newNames)

        if (event.target.value.length === 2 && index < playerRef.length - 1) {
            playerRef[index + 1].current.focus();
        }
    }
    
    // Handles autofocusing when the CSS animation is still going
    useEffect(() => {
       
        if(isSliding === 3)  {
            let timeout = 1000
            if(resetTime) {
                timeout = 0
            }
            setTimeout(() => {
                const firstEmptyIndex = playerNames.findIndex((value) => value.length < 2);
                if (firstEmptyIndex !== -1) {
                playerRef[firstEmptyIndex].current.focus();
                }
                setResetTime(true)
                
            }, timeout)      
        }
        
    }, [playerNames, isSliding])


    const inputPlayers = () => {
        setIsSliding(4)

    }

    return(<div>
        <PaperBack isSliding={isSliding} order={3}>
        
        <PaperContent width={"100%"} height={"100%"}>
            <Grid item xs={6} md={6}>
                <Typography marginBottom={5} variant="h3" color="primary">{game===1?("Triumph & Tragedy"):(game===2?("Conquest & Consequence"):("Conquest & Tragedy"))}</Typography>        
            </Grid>
            <Grid item xs={6} md={6}>
                <Typography variant="h4" color="primary">Input player initial(s)</Typography>        
            </Grid>
            <Grid item xs={9} md={9}>
                
                <FormControl component="fieldset">
                <FormGroup aria-label="position" >
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                <Grid item  xs={2} md={2}>
                    <TextField
                    inputRef={playerRef[0]}
                    value={playerNames[0]}
                    label={"P1"}
                    inputProps={{ maxLength: 2 }}
                    onChange={
                        (e) => handleFocus(0, e)
                        }
                    />
                </Grid>
                <Grid item xs={2} md={2} margin={1}>
                    <TextField
                    inputRef={playerRef[1]}
                    value={playerNames[1]}
                    label={"P2"}
                    inputProps={{ maxLength: 2 }}
                    onChange={
                        (e) => handleFocus(1, e)
                        }
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <TextField
                    inputRef={playerRef[2]}
                    value={playerNames[2]}
                    label={"P3"}
                    inputProps={{ maxLength: 2 }}
                    onChange={
                        (e) => handleFocus(2, e)
                        }
                    />
                </Grid>
                
                    
                    
                </Grid>
                </FormGroup>
                </FormControl>
                

            </Grid>
            <Grid item xs={6} md={6}>
                <Button disabled={playerNames[2]===""} onClick={inputPlayers}>
                    Create game
                    <ArrowForwardRoundedIcon/>
                </Button>

            </Grid>

        </PaperContent>

        </PaperBack>
        
    </div>)
}

export default Players