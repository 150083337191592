import { useEffect, useState } from "react";
import { useRef } from "react";
import Start from "./GMTRandomizer/Start";
import Game from "./GMTRandomizer/Game";
import Countries from "./GMTRandomizer/Countries";
import Players from "./GMTRandomizer/Players";
import GameSummary from "./GMTRandomizer/GameSummary";
import OnlyRules from "./GMTRandomizer/OnlyRules";
import PaperWindow from "../components/PaperWindow";

/*
 * Simple GMT Triumph and Tragedy / Conquest and Consequence randomizer 
 * What is special: 
 *  - First app with a more divided structure
 *  - First app with CSS combined with MUI components
 *  - Attempts at more componentified components with reuse in mind.
 *  - Contains disgusting amount of props, another candidate to Reduxify
 */ 

const GMTapp = () => {

    const [isSliding, setIsSliding] = useState(0);
    const [game,setGame] = useState(null)

    const [playerNames, setPlayerNames] = useState(["","",""])
    const [countries, setCountries] = useState([])
    const [randomPlayers, setRandomPlayers] = useState(["","",""])
    const [randomCountries, setRandomCountries] = useState([])
    const [gameDetails,setGameDetails] = useState([])

    


    const createGame = () => {

        let playerArray = playerNames.filter(item=> randomPlayers.indexOf(item)===-1)
        let countryArray = []
            for(let i = 0; i < countries.length; i++) {
                if(randomPlayers[i] === "") {
                    countryArray.push(countries[i])
                }
            }

        for (let i = 0; i < playerArray.length; i++) {
            const j = Math.floor(Math.random() * (i + 1));
            [playerArray[i], playerArray[j]] = [playerArray[j], playerArray[i]];
        }

        for (let i = 0; i < countryArray.length; i++) {
            const j = Math.floor(Math.random() * (i + 1));
            [countryArray[i], countryArray[j]] = [countryArray[j], countryArray[i]];
        }

        let combinedArray = []
        for(let i = 0; i<countries.length; i++){
            if(randomPlayers[i] === "") {
                console.log(countryArray)
                console.log(playerArray)
                let country = countryArray.splice(0,1)[0]
                let name = playerArray.splice(0,1)[0]
                
                console.log(countryArray)
                console.log(playerArray)
                
                combinedArray.push(country)
                combinedArray.push(name)

            }
            else {
                combinedArray.push(countries[i])
                combinedArray.push(playerNames[i])
            
            }
        }
        console.log(combinedArray)
        setGameDetails(combinedArray)
         
    }

    useEffect(() => {
        console.log("UPDATE")
        if(isSliding===4) {
            createGame()
        }  
    },[isSliding, game, playerNames, countries, randomPlayers, randomCountries])

    return(<div>
        {/*<PaperWindow>*/}
            <Start isSliding={isSliding} setIsSliding={setIsSliding}></Start>
            <Game isSliding={isSliding} setIsSliding={setIsSliding} setGame={setGame}></Game>
            <Countries isSliding={isSliding} setIsSliding={setIsSliding} game={game} setCountries={setCountries} playerNames={playerNames} setPlayerNames={setPlayerNames} randomPlayers={randomPlayers} setRandomPlayers={setRandomPlayers}></Countries>
            <Players isSliding={isSliding} setIsSliding={setIsSliding} game={game} playerNames={playerNames}setPlayerNames={setPlayerNames} randomPlayers={randomPlayers}></Players>
            <GameSummary isSliding={isSliding} game={game} gameDetails={gameDetails}></GameSummary>
            <OnlyRules isSliding={isSliding}></OnlyRules>
        {/*</PaperWindow>*/}
    </div>)

}

export default GMTapp



    