
import {Card, Button, CardActions, CardContent, Typography, TextField, Grid2 } from '@mui/material';
import { useState } from 'react';

const PostingTool = ({posts,setPosts, x,y}) => {
    

    const [postTitle, setPostTitle] = useState("")
    const [postContent, setPostContent] = useState("")

    const handlePost = () => {
        setPosts([...posts, {title: postTitle, content: postContent}])
        setPostTitle("")
        setPostContent("")
    }
 
    return(
            <Card sx={{
                margin: 3, 
                padding: 1,
                width: "10vw",
                height: "20vh",
                position: 'absolute',
                top: y-160,
                left: x-160,
                display: 'flex',
                backgroundColor: 'white', // Background color for the Card
                boxShadow: 3, // Optional: to give a shadow effect
                zIndex: 1,}}>
                <Grid2 container direction="column" sx={{justifyContent: "center", display: "flex", alignItems: "center", padding: 0}}>
                    <Grid2 size={12}>
                        <Typography variant="h4" color="primary.main" >
                            New postcard
                        </Typography>
                        
                        <TextField value={postTitle} placeholder="Write a title..." required inputProps={{
                            maxLength: 20,
                            style: {fontSize:12}
                            }}
                            onChange={(e) => setPostTitle(e.target.value)}
                            size="small">

                        </TextField>
                        <TextField sx={{width: "100%", height: "50%"}} required multiline value={postContent} placeholder="Write content..." inputProps={{
                            maxLength: 144,
                            style: {fontSize:12}
                            }} onChange={(e) => setPostContent(e.target.value)}
                            size="small">

                        </TextField>
                        <Button disabled={postTitle === "" || postContent === ""} onClick={handlePost} variant="filled" color="primary.main">
                            Post
                        </Button>
                    </Grid2>
                
                </Grid2>
                
            </Card>)

}

export default PostingTool
