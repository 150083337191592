import { Card, CardContent, Typography } from "@mui/material"

const PostCard = ({post, x ,y}) => {
    
    

    return(
        <div>
            <Card sx={{margin: 0, padding: 0,
                position: 'absolute',
                maxWidth:200,
                width: 200,
                height: 200,
                top: y-100,
                left: x-100,
                display: 'flex',
                backgroundColor: 'white', // Background color for the Card
                boxShadow: 3, // Optional: to give a shadow effect
                zIndex: -1,
                overflow: "hidden",
                boxSizing: "border-box"}}>
                <CardContent sx={{ boxSizing: "border-box", padding: 1, margin: 0,overflow: 'visible',wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', maxWidth: 'inherit', textAlign: "center",}}>
                    <Typography variant="h5" color="primary.main" sx={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                        {post.title}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: 12, wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                        {post.content}
                    </Typography>
                </CardContent>
            </Card>
        </div>)

}

export default PostCard