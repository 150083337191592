import * as React from 'react';
import { Box,Drawer,List,Divider,ListItem,ListItemButton,ListItemIcon,ListItemText,IconButton, Typography } from '@mui/material';
import Navigate from "./Navigate.jsx"
import ExtensionIcon from '@mui/icons-material/Extension';
import CasinoIcon from '@mui/icons-material/Casino';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import AppsIcon from '@mui/icons-material/Apps';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useState } from 'react';

export default function Sidebar() {

  // Sidebar open or not
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  // Contains elements to access all the content on the page
  const DrawerList = (
    <Box sx={{ width: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }} role="presentation" onClick={toggleDrawer(false)}>
      <List sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
        <Typography variant='h5' color="primary.main">
          Applications
        </Typography>
        <ListItem >
          
          <Navigate url={"/memgame"} title={"Memory game"}><ExtensionIcon/></Navigate>
        </ListItem>

        <Divider/>
          
        <ListItem>
          <Navigate url={"/gmtapp"} title={"GMT Game aligner"}><CasinoIcon/></Navigate>
        </ListItem>
        
       

        <ListItem>
          <Navigate url={"/cardgame"} title={"Card game"}><FontDownloadIcon/></Navigate>
        </ListItem>

        <Divider/>

        <ListItem>
          <Navigate url={"/trainingcards"} title={"Training cards"}><CreditCardIcon/></Navigate>
        </ListItem>


        <Typography variant='h5' color="primary.main">
          Other
        </Typography>

        <Divider/>
        <ListItem>
          <Navigate url={"/postwall"} title={"Wall of post"}><LocalPostOfficeIcon/></Navigate>
        </ListItem>
        <Divider/>
        <ListItem>
          <Navigate url={"/cvwall"} title={"Accomplishments"}><MilitaryTechIcon/></Navigate>
        </ListItem>
          
      </List>
    </Box>
  );

  return (
    <div>
        
      <IconButton
       size="large"
       edge="start"
       color="inherit"
       aria-label="menu"
       sx={{ mr: 2 }}
       onClick={toggleDrawer(true)}>
       <AppsIcon/>
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}