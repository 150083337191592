import { createTheme } from '@mui/material/styles'


const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#bd982a',
    },
    secondary: {
      main: '#810434',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    components: {
      // Customize InputLabel
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'white', // Set InputLabel color
          },
          // Set InputLabel color when focused
          outlined: {
            '&.Mui-focused': {
              color: 'white',
            },
          },
        },
      },
      // Customize Select
      MuiSelect: {
        styleOverrides: {
          root: {
            color: 'white', // Set the color of the selected item
            '& .MuiSelect-icon': {
              color: 'white', // Set the color of the dropdown icon
            },
          },
        },
      },
      // Customize OutlinedInput for border
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: 'white', // Set the border color
            },
            '&:hover fieldset': {
              borderColor: 'white', // Set the border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white', // Set the border color when focused
            },
          },
        },
      },
    },
  },

});

export default theme