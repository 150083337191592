import Matter from 'matter-js';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import PostingTool from './PostingTool';
import PostCard from './PostCard';


const WorldEngine = ({posts,viewState,setPosts}) => {
    const sceneRef = useRef(null); // Ref to the container where Matter.js will render
    const [toolPosition, setToolPosition] = useState({ x: 0, y: 0 });
    const [created, setCreated] = useState(false)
    
    const [objects, setObjects] = useState([])
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const engineRef = useRef(Matter.Engine.create());
    const [bodies, setBodies] = useState([]);


    // Detect the size of the window on load for determining MatterJS placements
    useEffect(() => {
        // Function to update the dimensions based on the container's size
        const updateDimensions = () => {
          if (dimensions.width !== 0 || dimensions.height !== 0) return;
          if (sceneRef.current) {
            const { width, height } = sceneRef.current.getBoundingClientRect();
            setDimensions({ width, height });
            setToolPosition({x: window.innerWidth, y:window.innerHeight})
          }
        };
    
        // Update dimensions on mount and whenever the window is resized
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        
        // Cleanup event listener on unmount
        return () => {
          window.removeEventListener('resize', updateDimensions);
        };
      }, []);
      
      
    
      useEffect(() => {
        if (dimensions.width === 0 || dimensions.height === 0) return;
        
        if(!viewState) {
            setCreated(true)
            
            const { Engine, Render, Runner, Bodies, Composite, Mouse, MouseConstraint, Events, World} = Matter;
    
            // Create an engine
            const engine = engineRef.current;
            const world = engineRef.current.world
            // Create a renderer with transparent background
            const render = Render.create({
              element: sceneRef.current, // Attach to the React component's ref
              engine: engine,
              options: {
                width: dimensions.width,
                height: dimensions.height,
                wireframes: false, // For rendering visible shapes
                background: 'transparent', // Set background to transparent
              },
            });
        
            Render.run(render);
        
            const runner = Runner.create();
            Runner.run(runner, engine);
            // Create bodies
            
            const ground = Bodies.rectangle(window.innerWidth/2, window.innerHeight-100, window.innerWidth, 100, { isStatic: true, render: {
              fillStyle: 'transparent',  // Makes the body transparent
              strokeStyle: 'transparent', // Ensures no border is drawn
              lineWidth: 0, // No border thickness
              }});
            const roof = Bodies.rectangle(window.innerWidth/2, 0, window.innerWidth, 100, { isStatic: true, render: {
              fillStyle: 'transparent',  
              strokeStyle: 'transparent', 
              lineWidth: 0, 
              }});
            const left = Bodies.rectangle(0, window.innerHeight/2, 100, window.innerHeight, { isStatic: true, render: {
              fillStyle: 'transparent',  
              strokeStyle: 'transparent', 
              lineWidth: 0,
              }});
            const right = Bodies.rectangle(window.innerWidth, window.innerHeight/2, 100, window.innerHeight, { isStatic: true, render: {
              fillStyle: 'transparent',  
              strokeStyle: 'transparent', 
              lineWidth: 0,
              }});
              
            const postBox = Bodies.rectangle(window.innerWidth/2, window.innerHeight/2, 250, 250, { render:
              {fillStyle: 'transparent',
                isStatic: false,
                inertia: Infinity}});
            
            // Add bodies
            setObjects([world, [ground, roof, left, right, postBox]])
            World.add(world, [ground,roof,left,right,postBox]);
            World.add(world, bodies);
            
        
            // Create Mouse
            const mouse = Mouse.create(render.canvas);
            const mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
            });
            
            
            World.add(world, mouseConstraint);
                
            let isGrabbing = false;
            Events.on(engine, 'afterUpdate', () => {
              if (isGrabbing) {
                let lastMousePosition = { x: mouse.position.x, y: mouse.position.y };
              }
              setToolPosition({ x: postBox.position.x, y: postBox.position.y });
            });
        
          
        
              // Clean up on component unmount
            return () => {
              Render.stop(render);
              Runner.stop(runner);
              Engine.clear(engine);
              Composite.clear(engine.world);
              render.canvas.remove();
              render.canvas = null;
              render.context = null;
              render.textures = {};
            };
        }
        
        
        // Module aliases for convenience
        
        }, [dimensions, viewState]);
        
        // Add new elements, up to 20 due to lag 
        useEffect(() => {

          if(created && bodies.length < 21)  {
            const randomWidth = Math.floor(300 + Math.random() * (window.innerWidth-300 + 1));
            const randomHeight = Math.floor(300 + Math.random() * (window.innerHeight-600 + 1));
            console.log(randomWidth)
            console.log(randomHeight)
            const example = Matter.Bodies.rectangle(randomWidth, randomHeight, 200, 200, {
              render: { 
                fillStyle: "transparent",
                restitution: 0.8,
                density: 0.04,
                inertia: Infinity }, 
            });
            setBodies((prevBodies) => [...prevBodies, example]);
              Matter.World.add(engineRef.current.world, example)
            
          }
          
      
        }, [posts])
        
        return <div
        ref={sceneRef}
        id="matter-container"
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
          border: '1px solid white',
          position: 'relative',

          
        }}>
        {/* Custom Postingtool */}
        <PostingTool posts={posts} setPosts={setPosts} x={toolPosition.x} y={toolPosition.y}/>
        {posts.slice(0,20).map((post, index) => (
          <div key={index}>
            {/* Custom Postcard */}
          <PostCard post={post} 
          x={bodies[index]?.position.x }
          y={bodies[index]?.position.y }></PostCard>
          </div>
        ))}
        </div>

}

export default WorldEngine