
import WorldEngine from './WorldEngine';

const PlayBox = ({posts,  viewState, setPosts}) => {
  

    return (
      
      <div>
        <div>
        <WorldEngine posts={posts} viewState={viewState} setPosts={setPosts}/>
        </div>
      </div>
    );
};

export default PlayBox;
