export const imageImport = {
    wkp: {
        url: "https://upload.wikimedia.org/wikipedia/commons/c/c1/WPK_symbol.svg",
        alt: "Workers party of Korea",
        type: 0
    },
    wps: {
        url: "https://upload.wikimedia.org/wikipedia/en/3/34/Workers%27_Party_of_Singapore_logo.png",
        alt: "Workers party of Singapore",
        type: 1
    },
    
    kpt: {
        url: "https://upload.wikimedia.org/wikipedia/commons/a/a4/LogoKPT.svg",
        alt: "Workers party of Tadzikistan",
        type: 2
    },
    skp: {
        url: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Logo_-_Suomen_Kommunistinen_Puolue.png",
        alt: "Workers party of Finland",
        type: 3
    }, 

    ksc: {
        url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Emblem_of_the_Communist_Party_of_Czechoslovakia.svg",
        alt: "Workers party of Czechia",
        type: 4
    }, 
    vkp: {
        url: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Emblem_of_Vietnam.svg",
        alt: "Workers party of Vietnam",
        type: 5
    }, 

    nkp: {
        url: "https://upload.wikimedia.org/wikipedia/commons/3/39/CPN-UML.svg",
        alt: "Workers party of Nepal",
        type: 6
    }, 
    awp: {
        url: "https://upload.wikimedia.org/wikipedia/commons/0/02/Machete_and_Gear.svg",
        alt: "Workers party of Angola",
        type: 7
    }, 


}