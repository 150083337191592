export const contentRussianVerbs = [
    { phrase: ["бежать"], trans: ["to run"] },
    { phrase: ["думать"], trans: ["to think"] },
    { phrase: ["есть"], trans: ["to eat"] },
    { phrase: ["пить"], trans: ["to drink"] },
    { phrase: ["говорить"], trans: ["to speak"] },
    { phrase: ["работать"], trans: ["to work"] },
    { phrase: ["читать"], trans: ["to read"] },
    { phrase: ["писать"], trans: ["to write"] },
    { phrase: ["смотреть"], trans: ["to watch"] },
    { phrase: ["слушать"], trans: ["to listen"] },
    { phrase: ["идти"], trans: ["to go (on foot)"] },
    { phrase: ["ехать"], trans: ["to go (by vehicle)"] },
    { phrase: ["плавать"], trans: ["to swim"] },
    { phrase: ["спать"], trans: ["to sleep"] },
    { phrase: ["танцевать"], trans: ["to dance"] },
    { phrase: ["покупать"], trans: ["to buy"] },
    { phrase: ["продавать"], trans: ["to sell"] },
    { phrase: ["учить"], trans: ["to teach"] },
    { phrase: ["учиться"], trans: ["to learn"] },
    { phrase: ["понимать"], trans: ["to understand"] }
];