
import { Box, Paper, Button} from "@mui/material"
import Grid2 from "@mui/material/Grid2"
import PostingTool from "./PostWall/PostingTool"
import PostCard from "./PostWall/PostCard"
import { useState } from "react"
import PlayBox from "./PostWall/PlayBox"
import Archive from "./PostWall/Archive"

/*
 * Simple flashy postwall for leaving messages to me and other visitors
 * What is special: 
 *  - Uses MatterJS library in combination with React elements.
 *  - Detects window size for MatterJS container placement
 */ 

const PostWall = () => {

    // App variables
    const [posts, setPosts] = useState([])
    const [viewState, setViewState] = useState(false)

    {/*const handleArchive = () => {
        setViewState(!viewState)

    }*/}

    return (
        <Box>
            {/*<Paper>
              <Button size="small" onClick={handleArchive}>
                Show archive
              </Button>
            </Paper>
            {viewState && <Archive posts={posts}></Archive>*/}
            {!viewState && <PlayBox posts={posts} viewState={viewState} setPosts={setPosts}
            style={{ visibility: viewState ? 'hidden' : 'visible' }}></PlayBox>}


            {//</Box></Grid2>
}

        </Box>
    )  
}

export default PostWall