export const contentRussianSlang = [
    { phrase: ["Чувак/Чувиха"], trans: ["Dude/Dudette"] },
    { phrase: ["Бомба"], trans: ["Bomb/Amazing"] },
    { phrase: ["Всё ништяк"], trans: ["It’s all good"] },
    { phrase: ["Как сам?"], trans: ["What’s up?"] },
    { phrase: ["Офигеть"], trans: ["No way/Wow"] },
    { phrase: ["Круто"], trans: ["Cool"] },
    { phrase: ["Да не гони!"], trans: ["Seriously?"] },
    { phrase: ["Хавать"], trans: ["To eat"] },
    { phrase: ["Бухать"], trans: ["To drink"] },
    { phrase: ["Бухой/Бухая"], trans: ["Drunk"] },
    { phrase: ["Тусить"], trans: ["To hang out"] },
    { phrase: ["Хрен знает"], trans: ["Who knows?"] },
    { phrase: ["Мне до лампочки"], trans: ["I don’t care"] },
    { phrase: ["Зачет"], trans: ["Noted"] },
    { phrase: ["Бабки"], trans: ["Money"] },
    { phrase: ["Чёрт"], trans: ["Shoot!"] },
    { phrase: ["Блин"], trans: ["Damn it/Shit"] },
    { phrase: ["Сука"], trans: ["Bitch"] },
    { phrase: ["Грузить"], trans: ["To bore with too much talking"] },
    { phrase: ["Лох"], trans: ["Gullible/Idiot"] },
    { phrase: ["Достал/Досталa"], trans: ["To be annoyed"] },
    { phrase: ["Бычить"], trans: ["To be angry"] },
    { phrase: ["Кайфонуть"], trans: ["To have a pleasant pastime"] },
    { phrase: ["Америкос"], trans: ["American"] }
];