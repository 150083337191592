import { AppBar,Toolbar,Button, Grid2 } from "@mui/material"
import Stopwatch from "./Stopwatch"
import { useState } from "react"


const GameBar = ({language, setLanguage, started, correct,reset,paused, wrongs}) => {

    // Change language
    const handleLanguage = () =>{
        if(language[0]) {
            setLanguage([false, true])
        }
        else {
            setLanguage([true, false])
        }
        
    }

    return (

        <Grid2 size={12}>
            <AppBar sx={{boxSizing: "border-box", padding: 2, position: "relative", backgroundColor: "#9A365C", alignItems: "center", width: "100%"}}>
                <Toolbar> 
                {!started &&
                <div>
                <Button color="secondary" sx={{margin: 1}} variant={language[0]?"outlined":"contained"} disabled={language[0]} onClick={handleLanguage}>
                    Dutch
                </Button>
                <Button color="secondary" sx={{margin: 1}} variant={language[1]?"outlined":"contained"} disabled={language[1]} onClick={handleLanguage}>
                    Russian
                </Button>
                </div>
                }
                {/* Custom stopwatch component */}
                {started && <Stopwatch correct={correct} reset={reset} paused={paused} wrongs={wrongs}></Stopwatch>}
                
                </Toolbar>
            </AppBar>
        </Grid2>
    )
    
}

export default GameBar