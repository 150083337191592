import { legacy_createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk"

// Redux-devtools extension library
import { composeWithDevTools } from "@redux-devtools/extension";

import imageReducer from "./reducers/imageReducer";
import notifcationReducer from "./reducers/notificationReducer"


/*import reducerReducer from "./reducers/reducerReducer"*/
export const reducers = combineReducers({
  /*reducer: reducerReducer,*/
  image: imageReducer,
  notification: notifcationReducer,
});
  
export default legacy_createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
    
);
  