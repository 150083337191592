import { Card, CardContent, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"



const WordCard = ({showContent, content}) => {

    const [showCard, setShowCard] = useState(true)

    // Detect whether card should be visible
    useEffect(() => {
        if(showContent) {
            setShowCard(true)
        }
        else {
            setShowCard(false)
        }
    }, [showContent])

    const handleShow = () => {
        setShowCard(!showCard)
    }


    return <div>

        <Card onClick={handleShow} sx={{padding:0,
        backgroundColor: showCard ? "#CAAC54" : "#846A1D",  
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '150px',
        minHeight: '8vh',
        width: '100%',
        height: "100%",  
        maxHeight: {xs: "30vh", md: "12vh"},}}>
            <CardContent sx={{padding: 3}}>
            {showCard && content.map(item => (
                <Typography variant="h6" sx={{fontSize: 'calc(0.7 * min(3vh, 20vw))', lineHeight: 1, textAlign: 'center'}}>
                    <p>{item}</p>
                </Typography>
            ))}
            </CardContent>
        </Card>
    </div>
}

export default WordCard