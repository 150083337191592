export const contentRussianConvos = [
    { 
      phrase: [
        "Привет, как дела?",
        "Здравствуйте! Хорошо, спасибо. А у вас?",
        "Тоже хорошо, спасибо!"
      ],
      trans: [
        "Hi, how are you?",
        "Hello! I'm fine, thank you. And you?",
        "I'm good too, thank you!"
      ]
    },
    { 
      phrase: [
        "Вы где работаете?",
        "Я работаю в банке. А вы?",
        "Я работаю в школе."
      ],
      trans: [
        "Where do you work?",
        "I work at a bank. And you?",
        "I work at a school."
      ]
    },
    { 
      phrase: [
        "Какой у вас любимый цвет?",
        "Мне нравится синий цвет.",
        "А мне зеленый."
      ],
      trans: [
        "What is your favorite color?",
        "I like the color blue.",
        "And I like green."
      ]
    },
    { 
      phrase: [
        "Что вы делаете в выходные?",
        "Я обычно хожу в кино.",
        "Я предпочитаю отдыхать дома."
      ],
      trans: [
        "What do you do on weekends?",
        "I usually go to the movies.",
        "I prefer to relax at home."
      ]
    },
    { 
      phrase: [
        "Вы любите путешествовать?",
        "Да, мне очень нравится путешествовать.",
        "Куда вы обычно ездите?"
      ],
      trans: [
        "Do you like to travel?",
        "Yes, I really enjoy traveling.",
        "Where do you usually go?"
      ]
    },
    { 
      phrase: [
        "Какой ваш любимый фильм?",
        "Мой любимый фильм - 'Интерстеллар'.",
        "Я люблю комедии."
      ],
      trans: [
        "What is your favorite movie?",
        "My favorite movie is 'Interstellar'.",
        "I love comedies."
      ]
    },
    { 
      phrase: [
        "Когда у вас день рождения?",
        "Мой день рождения в январе.",
        "Мой день рождения в июле."
      ],
      trans: [
        "When is your birthday?",
        "My birthday is in January.",
        "My birthday is in July."
      ]
    },
    { 
      phrase: [
        "Вы любите спорт?",
        "Да, я занимаюсь бегом.",
        "Я предпочитаю плавание."
      ],
      trans: [
        "Do you like sports?",
        "Yes, I do running.",
        "I prefer swimming."
      ]
    },
    { 
      phrase: [
        "Что вы думаете о книге?",
        "Она очень интересная.",
        "Да, я согласен, она захватывающая."
      ],
      trans: [
        "What do you think about the book?",
        "It's very interesting.",
        "Yes, I agree, it's captivating."
      ]
    },
    { 
      phrase: [
        "Какая у вас любимая кухня?",
        "Мне нравится итальянская кухня.",
        "Я предпочитаю японскую кухню."
      ],
      trans: [
        "What is your favorite cuisine?",
        "I like Italian cuisine.",
        "I prefer Japanese cuisine."
      ]
    },
    { 
      phrase: [
        "Какой у вас план на сегодня?",
        "Я планирую посетить друзей.",
        "Я собираюсь сделать домашнее задание."
      ],
      trans: [
        "What is your plan for today?",
        "I plan to visit friends.",
        "I am going to do homework."
      ]
    },
    { 
      phrase: [
        "Вы играете на музыкальных инструментах?",
        "Да, я играю на гитаре.",
        "А я на пианино."
      ],
      trans: [
        "Do you play any musical instruments?",
        "Yes, I play the guitar.",
        "And I play the piano."
      ]
    },
    { 
      phrase: [
        "Какой ваш любимый праздник?",
        "Мне нравится Новый год.",
        "Я люблю Рождество."
      ],
      trans: [
        "What is your favorite holiday?",
        "I like New Year.",
        "I love Christmas."
      ]
    },
    { 
      phrase: [
        "Вы часто читаете книги?",
        "Да, я читаю каждую неделю.",
        "Я читаю только в отпуске."
      ],
      trans: [
        "Do you read books often?",
        "Yes, I read every week.",
        "I only read during vacation."
      ]
    },
    { 
      phrase: [
        "Где вы учились?",
        "Я учился в Москве.",
        "Я учился в Санкт-Петербурге."
      ],
      trans: [
        "Where did you study?",
        "I studied in Moscow.",
        "I studied in St. Petersburg."
      ]
    },
    { 
      phrase: [
        "Какая у вас любимая музыка?",
        "Мне нравится классическая музыка.",
        "Я предпочитаю рок."
      ],
      trans: [
        "What is your favorite music?",
        "I like classical music.",
        "I prefer rock."
      ]
    },
    { 
      phrase: [
        "Вы пробовали новый ресторан?",
        "Да, и еда там отличная.",
        "Я еще не был там."
      ],
      trans: [
        "Have you tried the new restaurant?",
        "Yes, and the food is great.",
        "I haven't been there yet."
      ]
    },
    { 
      phrase: [
        "Какой у вас любимый сезон?",
        "Мне нравится осень.",
        "Я люблю лето."
      ],
      trans: [
        "What is your favorite season?",
        "I like autumn.",
        "I love summer."
      ]
    },
    { 
      phrase: [
        "Вы изучаете иностранные языки?",
        "Да, я изучаю английский.",
        "Я учу испанский."
      ],
      trans: [
        "Do you study foreign languages?",
        "Yes, I study English.",
        "I am learning Spanish."
      ]
    },
    { 
      phrase: [
        "Что вы думаете о фильме?",
        "Он был захватывающим.",
        "Да, особенно концовка."
      ],
      trans: [
        "What do you think about the movie?",
        "It was thrilling.",
        "Yes, especially the ending."
      ]
    },
    { 
      phrase: [
        "Какой ваш любимый спорт?",
        "Мне нравится футбол.",
        "Я предпочитаю баскетбол."
      ],
      trans: [
        "What is your favorite sport?",
        "I like soccer.",
        "I prefer basketball."
      ]
    },
    { 
      phrase: [
        "Вы собираетесь в отпуск?",
        "Да, я еду в Турцию.",
        "Я планирую поехать в Испанию."
      ],
      trans: [
        "Are you going on vacation?",
        "Yes, I am going to Turkey.",
        "I plan to go to Spain."
      ]
    }
  ]