export const SHOW_IMAGES = "SHOW_IMAGES"
export const LOAD_IMAGES = "LOAD_IMAGES"
export const SHUFFLE_IMAGES = "SHUFFLE_IMAGES"
export const HIDE_IMAGES = "HIDE_IMAGES"

export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"



//export const RECUCE_REDUCER = "REDUCE_REDUCER"