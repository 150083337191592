

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../constants";

const initialState = []


const notificationReducer = (state = initialState, action) => {

    const newNotification = action.payload;
    switch(action.type) {
        
        case ADD_NOTIFICATION:
            if(state.filter(notification => notification.category === action.payload.category).length === 0) {
                return [...state, action.payload]
            }
            else {
            
            
            if (state.map(notification => notification.category).indexOf(newNotification.category) !== -1) {
            return [
                ...state.slice(0, state.map(notification => notification.category).indexOf(newNotification.category)),
                newNotification,
                ...state.slice(state.map(notification => notification.category).indexOf(newNotification.category) + 1)
            ];
        }
            return state;
            }

        case REMOVE_NOTIFICATION:
            return state.filter(cat => cat.category !== action.payload.category);

        default: 
            return state;
    }
}

export default notificationReducer;