import Notifications from "../components/Notifications"
import MemoryCard from "./Memorygame/MemoryCard"

import { Box, Button } from "@mui/material"
import { Grid,Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { imageImport } from "../../images"
import { loadImages, shuffleImages } from "../../Model/redux/actionCreators/imageActions"

/*
 * Simple memory game with 16 matchable cards. 
 * What is special: 
 *  - Uses Redux state management
 *  - Loading images from URLS in React
 *  - (Somewhat forced) MVC structure (will most likely not use this unless logic is HUGE)
 *  - First proper React MUI application
 *  - First application made from self-formed requirements from Git
 *  - Mostly one file monolith (ew), and custom MemoryCard and Notifications components
 *  - Notifications component is re-used from course COMP.CS.510 made by me, also uses Redux
 */ 


const Memgame = () => {

    // Game variables and redux
    const dispatch = useDispatch()
    const reduxImages = useSelector(state => state.image)
    const [loaded, setLoaded] = useState(false)
    const [reset, setReset] = useState(false)
        
    //Load images from file of urls
    useEffect(() => {

        const loadImages = async () => {
            
            const imagesArray = [
                imageImport.wkp, imageImport.wkp, imageImport.skp, imageImport.skp,
                imageImport.wps, imageImport.wps, imageImport.kpt, imageImport.kpt,
                imageImport.ksc, imageImport.ksc, imageImport.vkp, imageImport.vkp,
                imageImport.nkp, imageImport.nkp, imageImport.awp, imageImport.awp,
            ]
            dispatchImages(imagesArray)
        }
        
        loadImages()
    }, [reset]);

    // Put images to redux storage
    const dispatchImages = (imagesArray) => {
        dispatch(loadImages(imagesArray))
        dispatch(shuffleImages(reduxImages))
        setLoaded(true)
    }
    if(!loaded) {
        return(<div>Loading images...</div>)
    }
    return(<div className="memory">
        {/*</PaperWindow>*/}
    <Box>
        <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", }}>
            <Grid item xs={2} md={4}>
                <Box/>
            </Grid>
            <Grid item xs={8} md={4}>
                <Paper elevation={6} sx={{padding: 2, margin: 2}}>
                    <Grid container sx={{textAlign:"center", justifyContent:"center"}}>
                        <Grid item xs={6} md={6} sx={{textAlign:"left", justifyContent:"center"}}>
                            <Box>
                                {/* Custom notifications, re-used from COMP.CS.510 made by me */}
                                <Notifications/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={6} sx={{textAlign:"right", justifyContent:"center"}}>
                        <Button sx={{padding: 4}} size="small"onClick={() => setReset(!reset)}>Reset game</Button>
                        </Grid>
                        
                        

                    </Grid>
                   
                    
                    
                </Paper>
            </Grid>
            <Grid item xs={2} md={4}>
                <Box/>
            </Grid>
            <Grid item xs={8} md={4}>
                <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1 
                  }}>
                <Paper 
                    elevation={6} 
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: "primary.main",
                        overflow: 'hidden',
                      }}
                >
                    <Grid 
                        container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: '100%',
                            boxSizing: 'border-box',
                            }}>
                        {reduxImages.map((image) => (
                        <Grid item xs={3} md={3} key={image.id} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 0,
                            boxSizing: 'border-box',
                            }}>
                            {/* Custom Memorycard*/ }
                            <MemoryCard image={image} />
                        </Grid>
                        ))}
                    </Grid>
                </Paper>
                </Box>
            </Grid>
            </Grid>
        </Box>
        {/*</PaperWindow>*/}
        
    </div>)

}

export default Memgame